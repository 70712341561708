import React from 'react'
import { t } from '../../pages/Translations/TranslationUtils';
import Button from '../atoms/Button';

interface Props {
    list: any,
    tabSwitch: any,
    activetab?: string,
    classname?: string,
    counts?: any,
}

const TabsView: React.FC<Props> = (props) => {
    return (
        <>
            {Object.values(props.list).map((value: any) => {
                let count = props?.counts?.find((item: any) => item?.status == value?.key) ?? {};
                return <>
                    <Button
                        key={value.title}
                        className={`tabsview  bg-transparent tab-btn me-4 position-relative ${value.key == props.activetab ? 'active border-0' : 'border border-2'} ${count?.count && "border-danger"}`}
                        handleClick={() => props.tabSwitch(value.key)}>
                        {t(value.title)}
                        {count?.count && value.title !== "Published" && (
                        <span className="p-3 tab-count">{count?.count}</span>
                        )}
                    </Button>
                </>
            })}
        </>
    )
}

export default TabsView;
// btn {
//     background-color: var(--raven-red);
//     border: 1px solid var(--raven-red);
//     border-radius: 4px;
//     color: var(--color-white);
//     font-size: var(--font-size-22);
//     height: 50px;
//     min-width: -webkit-max-content;
//     min-width: max-content;
//     padding: 0 2rem;
// }