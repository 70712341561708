import React, { useState } from "react";
import LabelField from '../../atoms/LabelField';
import Button from '../../atoms/Button';
import Popup from "../../../utils/popup";
import DivButtonMolecule from "../DivButtonMolecule";
import { t } from "../../../pages/Translations/TranslationUtils";
import { CommonConstants } from "../../../pages/Shipment/Constants/ShipmentConstants";

interface LabelWithButtonActionProps {
  label_text: string;
  setSelectedItem: any;
  selectedItem: any;
  list?: any;
  showIssueListPopup?: any;
  setIssueListShowPopup: (value: any) => void;
}

export const LabelWithButtonAction: React.FC<LabelWithButtonActionProps> = ({ label_text, setSelectedItem, selectedItem, list, showIssueListPopup, setIssueListShowPopup }) => {
  const [issue, setIssue] = useState([]);
  const predefinedIssueDetails = JSON.parse(localStorage.getItem('predefinedIssueDetails') || '[]');
  list = list.filter((item: any) =>
    !predefinedIssueDetails?.some((predefined: any) => predefined?.issue_type?.value == item?.issue_type_id)
  );
  const issueSelectHandler = () => {
    //show popup here
    setIssueListShowPopup(true);
  }

  const updateSelectedIssue = (issue: any) => {
    if (issue) {
      setIssue(issue)
    } else {
      setIssue([]);
    }
  }

  const handleSubmit = (submit: boolean) => {
    if (submit) {
      setSelectedItem(issue)
    } else {
      setSelectedItem(null)
    }
    setIssueListShowPopup(!showIssueListPopup)
  }

  return (
    <div className="d-flex align-items-center flex-wrap">
      <div className="me-2">
        {/* using LabelField atom component to show the label text */}
        <LabelField title={label_text} mandatory={true} />
      </div>

      {/* using button atom component to show the issue popup
        We need to pass the popup handler function from here being parent of it
      */}
      <Button
        title={t("Click here")}
        className="back-btn shadow-none text-decoration-none"
        handleClick={issueSelectHandler}
        disabled={false}
      />

      {showIssueListPopup && (
        <Popup
          title={t("Configuration: Issues")}
          body={<DivButtonMolecule
            title={t('Select your issue')}
            parentHandler={updateSelectedIssue}
            selectedItem={selectedItem}
            list={list}
          />}
          cancel={() => handleSubmit(false)}
          submit={() => handleSubmit(true)}
          yestext={t(CommonConstants.SAVE)}
          notext={t(CommonConstants.CANCEL)}
        />
      )}
    </div>
  );
};






