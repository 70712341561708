import { useEffect, useReducer, useRef, useState } from "react";
import Accordion from "../../../../components/molecules/Accordion";
import { t } from "../../../Translations/TranslationUtils";
import StageWiseTimeoutBody from "../../../../components/molecules/StageWiseTimeoutBody";
import ActionButtonGroup from "../../../../components/molecules/ActionButtonGroup";
import { UTILITYFN } from "../utility/Utility";
import PreDefinedQCIssues from "../../../../components/molecules/PreDefinedQCIssues";
import { GenerateRefreshToken } from "../../../../services/RefreshTokenService";
import { useNavigate } from "react-router-dom";
import { APICALL } from "../../../../services/ApiServices";
import { fetchWorkflowStages } from "../../../../routes/ApiEndPoints";


const initialForm = {
  timeoutState: [],
  predefinedIssueState: []
};

interface Props {
  draftSaved: boolean;
  saveAsDraft: string;
  saveAndNext: string;
  backbutton: string;
  toggleAlertMessage: () => void;
  setAlertMessage: (message: string) => void;
  formPageState: any;
  dispatchFormPageState: any;
  setPageDirection: (page: number) => void;
}

export interface StageWiseTimeoutInputItemsProps {
  stage_id: string;
  label: string;
  name1: string;
  name2: string;
  value1: string;
  value2: string;
}

export interface SelectedOption {
  value: string;
  label: string;
  color?: string;
}
export interface selectedIssueInterphase {
  issue_type: SelectedOption | null,
  zone: SelectedOption | null,
  environment: SelectedOption | null,
}

export interface PredefinedIssueErrorsInterphase {
  issue_type: string;
  zone: string;
  environment: string;
}

export interface Stage {
  stage_id: number;
  stage_name: string;
}

function EighthTa(props: Props) {
  const {
    draftSaved,
    saveAndNext,
    saveAsDraft,
    backbutton,
    toggleAlertMessage,
    setAlertMessage,
    formPageState,
    dispatchFormPageState,
    setPageDirection
  } = props

  const navigate = useNavigate();
  window.addEventListener('beforeunload', () => {
    sessionStorage.clear()
  });

  const [selectedIssues, setSelectedIssues] = useState<selectedIssueInterphase[]>([]);
  const [timeoutErrors, setTimeoutErrors] = useState<{ [key: string]: string }>({});
  const [predefinedIssueErrors, setPredefinedIssueErrors] = useState<PredefinedIssueErrorsInterphase>({ issue_type: "", zone: "", environment: "" });


  const [activeKey, setActiveKey] = useState<string | null>(null);

  const [stageWiseTimeoutInputItems, setStageWiseTimeoutInputItems] = useState<StageWiseTimeoutInputItemsProps[]>([]);

  const timeoutMapping: { [key: string]: { timeoutValue: string; popupTimeoutValue: string } } = {
    "sort": { timeoutValue: "14.5", popupTimeoutValue: "15.0" },
    "category_sort": { timeoutValue: "2.5", popupTimeoutValue: "3.0" },
    "internal_laundry": { timeoutValue: "4.5", popupTimeoutValue: "5.0" },
    "data_entry": { timeoutValue: "4.5", popupTimeoutValue: "5.0" },
    "quality_control": { timeoutValue: "2.5", popupTimeoutValue: "3.0" },
    "repair": { timeoutValue: "4.5", popupTimeoutValue: "5.0" },
    "quality_assurance": { timeoutValue: "2.0", popupTimeoutValue: "2.5" },
    "external_laundry": { timeoutValue: "14.5", popupTimeoutValue: "15.0" },
    "finishing": { timeoutValue: "4.5", popupTimeoutValue: "5.0" },
    "merchandising": { timeoutValue: "2.0", popupTimeoutValue: "2.5" },
    "photo_studio": { timeoutValue: "2.0", popupTimeoutValue: "2.5" },
    "photo_editing": { timeoutValue: "2.0", popupTimeoutValue: "2.5" },
    "publishing": { timeoutValue: "14.5", popupTimeoutValue: "15.0" }
  };

  const removeError = (fieldName: string) => {
    setTimeoutErrors((prevState) => ({
      ...prevState,
      [fieldName]: "", // Set the value of the field dynamically
    }));
  };

  const items = [
    { title: t('Stagewise timeout'), content: <StageWiseTimeoutBody stageWiseTimeoutInputItems={stageWiseTimeoutInputItems} setStageWiseTimeoutInputItems={setStageWiseTimeoutInputItems} timeoutErrors={timeoutErrors} removeError={removeError} /> },
    { title: t('Pre-defined issues'), content: <PreDefinedQCIssues selectedIssues={selectedIssues} setSelectedIssues={setSelectedIssues} predefinedIssueErrors={predefinedIssueErrors} setPredefinedIssueErrors={setPredefinedIssueErrors} /> },
  ];

  useEffect(() => {
    const fetchStages = async () => {
      try {
        const response = await APICALL.service(fetchWorkflowStages, 'GET', '');
        if (response?.status === 200) {
          const processStages = (stages: any[], timeoutState: any[] = []) => {
            return stages.map(stage => {
              const { timeoutValue, popupTimeoutValue } = timeoutMapping[stage.stage_name] || {};
              // Get user-entered values from timeoutState
              const matchingStage = timeoutState.find(item => item.stage_id == stage.stage_id);
              // Return final values, prioritizing user-entered values if available
              return {
                stage_id: stage.stage_id,
                label: t(stage.stage_name),
                name1: `${stage.stage_name}_timeout`,
                value1: matchingStage?.value1 ?? timeoutValue,
                name2: `${stage.stage_name}_popup_timeout`,
                value2: matchingStage?.value2 ?? popupTimeoutValue,
              };
            });
          };

          const workflowState = sessionStorage.getItem('workflowState')
            ? JSON.parse(sessionStorage.getItem('workflowState') ?? '')
            : null;

          const defaultStages = processStages(response.data, workflowState?.timeoutState ?? []);
          setStageWiseTimeoutInputItems(defaultStages);
          if (!workflowState) {
            // Save the default state to session storage
            sessionStorage.setItem(
              'workflowState',
              JSON.stringify({
                timeoutState: defaultStages,
                predefinedIssueState: selectedIssues
              })
            );
          }
        }
      } catch (error) {
        console.error("Error fetching stages:", error);
      }
    }
    fetchStages();
  }, []);

  async function validateFormData(event: React.FormEvent<HTMLFormElement>, draft?: boolean) {
    event.preventDefault();
    const newErrors: { [key: string]: string } = {};

    stageWiseTimeoutInputItems.forEach((item) => {
      if (!item.value1 || Number(item.value1) === 0) {
        newErrors[item.name1] = `This field is required`;
      }
      if (!item.value2 || Number(item.value2) === 0) {
        newErrors[item.name2] = `This field is required`;
      }
      if (item.value1 && item.value2 && Number(item.value1) > Number(item.value2)) {
        newErrors[item.name1] = `Redirection time canot be greater than popup time.`;
      }
      if (item.value1 && item.value2 && Number(item.value1) == Number(item.value2)) {
        newErrors[item.name2] = `Redirection time canot be equal to popup time.`;
      }
    });
    setTimeoutErrors(newErrors);
    let updatedFormPageState = { ...formPageState };

    if (Object.keys(newErrors).length !== 0) {
      setActiveKey('0');
      updatedFormPageState.pageEight.validationStatus = false;
      dispatchFormPageState({ ...formPageState, ...updatedFormPageState });
    } else {
      try {
        let token = await GenerateRefreshToken();
        const bp_id = Number(sessionStorage.getItem("brand_partner_id"));
        const response = await fetch(process.env.REACT_APP_MASTER_DATA_URL + "api/master/create-brand-partner",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify({
              "stageWise_timeout": stageWiseTimeoutInputItems,
              "predefined_issues": selectedIssues,
              "brand_partner_id": bp_id
            }),
          }
        );
        if (response?.status === 200) {
          if (draft) {
            navigate('/brand-partner/manage')
          }
          updatedFormPageState.pageEight.validationStatus = true;
          UTILITYFN.setCurrentPageData(props, 9);
          sessionStorage.setItem('workflowState', JSON.stringify({ timeoutState: stageWiseTimeoutInputItems, predefinedIssueState: selectedIssues }));
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  return (
    <>
      <form className="contract-meter-form flex-1 overflow-auto d-flex flex-column h-100" onSubmit={(e) => validateFormData(e)}>

        <Accordion items={items} className="flex-1 overflow-auto"
          activeKey={activeKey}
          setActiveKey={setActiveKey} />
        <ActionButtonGroup
          backTitle={t(backbutton)}
          saveAsDraftTitle={draftSaved ? "" : t(saveAsDraft)}
          saveAndNextTitle={t(saveAndNext)}
          handleBackClick={() => UTILITYFN.setCurrentPageData(props, 7)}
          handleSaveAsDraftClick={(e: any) => validateFormData(e, true)}
          handleSaveAndNextClick={(e: any) => validateFormData(e)}
        />
      </form>
    </>
  );
}

export default EighthTa;
