export const ENV_URL = window.location.origin;
export const REGEX_URL = '';
export const SERVICE_URL = process.env.REACT_APP_SERVICE_REGISTRY
export const IDENTITY_MANAGER_URL = process.env.REACT_APP_B_IDENTITY_MANAGER_URL
export const MASTER_DATA_URL = process.env.REACT_APP_MASTER_DATA_URL
export const WORKFLOWS_URL = process.env.REACT_APP_WORKFLOWS_URL;
export const WEBCONNECTOR_URL = process.env.REACT_APP_WEBCONNECTOR_URL;

export const LogoutLink = '';
export const PAGINATE_BY = 10;

//follow the below example to add your url endpoints

// EXAMPLE : export const getUserDetails = BASE_URL + 'account/get-user-details';

//add all your new urls from here onwards

//user end points
export const getusers = SERVICE_URL + 'service/b-identity-manager/manage-users';
export const getUsernames = SERVICE_URL + 'service/b-identity-manager/get-user-names';
export const createUserUrl = SERVICE_URL + 'service/b-identity-manager/create-user';
export const createRole = SERVICE_URL + 'service/b-identity-manager/create-role';
export const fetchRolesUrl = SERVICE_URL + 'service/b-identity-manager/manage-roles';
export const managePermissions = SERVICE_URL + 'service/b-identity-manager/manage-permissions';
export const updateCaptchaScore = SERVICE_URL + 'service/b-identity-manager/captcha-create';
export const ManageCaptchaScore = SERVICE_URL + 'service/b-identity-manager/captcha-manage';
export const fetchUserPermissions = SERVICE_URL + 'service/b-identity-manager/fetch-user-permissions';
export const fetchTimeout = SERVICE_URL + 'service/b-identity-manager/get-user-inactivity';
export const updateTimeout = SERVICE_URL + 'service/b-identity-manager/update-user-inactivity';

//get refresh token
export const getRefreshToken = SERVICE_URL + 'get-refresh-token';
export const uploadProfilePicture = IDENTITY_MANAGER_URL + 'api/profile-picture';

//master-data brand-partner
export const createBrandPartnerUrl = SERVICE_URL + "service/b-master-data/create-brand-partner";
export const fetchWorkflowStages = WORKFLOWS_URL + "api/workflow/get-stages";
export const getProductGroups = SERVICE_URL + "service/b-master-data/get-product-group";
export const locationAndCountryUrl = SERVICE_URL +  "service/b-master-data/location-country";
export const fetchAllBrandPartnerUrl = SERVICE_URL + "service/b-master-data/get-brand-partner"
export const fetchBrandPartnerSizesUrl = SERVICE_URL + "service/b-master-data/get-brand-partner-sizes"

/** media library end points */
export const createMediaLibraries = SERVICE_URL + "service/b-master-data/media-library-create"
export const deleteMediaLibraries = SERVICE_URL + "service/b-master-data/media-library-delete"
export const fetchMediaLibraries = SERVICE_URL + "service/b-master-data/media-library-get"
export const createMediaTags = SERVICE_URL + "service/b-master-data/media-tags-create"
export const manageMediaTags = SERVICE_URL + "service/b-master-data/media-tags-manage"
export const getMediaTags = SERVICE_URL + "service/b-master-data/media-tags-get"
export const deleteMediaTags = SERVICE_URL + "service/b-master-data/media-tags-delete"
export const saveFiles = SERVICE_URL + "service/b-master-data/media-save-files";
export const searchMedia = SERVICE_URL + "service/b-master-data/media-search";

// export const uploadFiles = SERVICE_URL + 'service/b-master-data/library-upload-files';
export const uploadFiles = MASTER_DATA_URL + 'api/media-library/upload-files';
export const fetchFiles = MASTER_DATA_URL + 'api/media-library/fetch-files';
export const deleteFiles = MASTER_DATA_URL + 'api/media-library/delete-files';


export const fetchProductCatalogue =  SERVICE_URL +  "service/b-master-data/get-catalogue";
export const importProductCatalogue = SERVICE_URL +  "service/b-master-data/import-catalogue";
export const updateCatalogue = SERVICE_URL +  "service/b-master-data/update-catalogue";
export const clearCatalogue = SERVICE_URL +  "service/b-master-data/clear-catalogue";

/**config data end points */
//fetch config data
export const fetchConfigData = SERVICE_URL + 'service/b-master-data/config-view';
export const editConfigData = SERVICE_URL + 'service/b-master-data/config-edit';
export const createConfigData = SERVICE_URL + 'service/b-master-data/config-create';
export const archieveConfigData = SERVICE_URL + 'service/b-master-data/config-archieve';
export const fetchTablesUrl = SERVICE_URL + 'service/b-master-data/fetch-tables';
export const fetchFormData = SERVICE_URL + 'service/b-master-data/get-form-data';
export const fetchWorkArea = SERVICE_URL + 'service/b-master-data/fetch-work-area';
export const fetchList = SERVICE_URL + 'service/b-master-data/config-list';
export const fetchEach = SERVICE_URL + 'service/b-master-data/config-each'
export const fetchBpwiseList = SERVICE_URL + 'service/b-master-data/bpwise-config-list';

/**translations */
export const fetchLanguageStringsUrl = SERVICE_URL + 'service/b-master-data/manage-translations';
export const createLanguageStringsUrl = SERVICE_URL + 'service/b-master-data/create-translations';
export const importTranslations = SERVICE_URL + 'service/b-master-data/import-translations';
export const fetchLanguagesUrl = SERVICE_URL + 'service/b-master-data/manage-languages';

/**mail template */
export const getMailTokens = SERVICE_URL + "service/b-master-data/get-mail-tokens";
export const createMailTemplate = SERVICE_URL + "service/b-master-data/mail-template-create";
export const manageMailTemplate = SERVICE_URL + "service/b-master-data/mail-template-manage";

/** data entry */
export const createDataEntry = WORKFLOWS_URL + "api/data-entry/create";
export const getDataEntry = WORKFLOWS_URL + "api/data-entry/get";
export const fetchRenewalRecord = WORKFLOWS_URL + "api/records/get"

/** sorting */
export const fetchSortUrl = WORKFLOWS_URL + 'api/sorting/get';
export const createSortUrl = WORKFLOWS_URL + 'api/sorting/create';

/** category sorting */
export const fetchCategorySortUrl = WORKFLOWS_URL + 'api/category-sort/get';
export const createCategorySortUrl = WORKFLOWS_URL + 'api/category-sort/create';

/** shipments */
export const getShipments = WORKFLOWS_URL + 'api/work-flow/shipments/get';
export const createShipments = WORKFLOWS_URL + 'api/work-flow/shipment/create';

/** Quality control */
export const createMerchandising = WORKFLOWS_URL + 'api/merchandising/create';
export const getIssues = WORKFLOWS_URL + 'api/merchandising/get-issues';

/** Quality control */
export const createqcissue = WORKFLOWS_URL + 'api/quality-control-issue/create';
export const deleteqcissue = WORKFLOWS_URL + 'api/quality-control-issue/delete';
export const getqc = WORKFLOWS_URL + 'api/quality-control/get';
export const updateqc = WORKFLOWS_URL + 'api/quality-control/create';

/** Repair */
export const createrepairissue = WORKFLOWS_URL + 'api/repair-issue/create';
export const getrepair = WORKFLOWS_URL + 'api/repair/get';
export const updaterepair = WORKFLOWS_URL + 'api/repair/update';

/** Finishing  */
export const createFinishing = WORKFLOWS_URL + 'api/finishing/update';
export const getFinishing = WORKFLOWS_URL + 'api/finishing/manage';

/** time tracker */
export const saveTime = WORKFLOWS_URL + 'api/workflows/time-tracker';

/** Quality assurance */
export const getQA = WORKFLOWS_URL + 'api/quality-assurance/get';
export const createQaIissue = WORKFLOWS_URL + 'api/quality-assurance-issue/create';
export const createQa = WORKFLOWS_URL + 'api/quality-assurance/create';

/** Pre publish */
// export const updatePrepublish = WORKFLOWS_URL + 'api/pre-publish/update';
export const updatePrepublish = WORKFLOWS_URL + 'api/publish';
export const fetchPublished = WORKFLOWS_URL + 'api/published';
export const fetchPublishStageItems = WORKFLOWS_URL + 'api/publishing';

export const fetchMerchracks = WORKFLOWS_URL +'api/fetch-merchrack'
/** Re publish */
export const rePublish = WORKFLOWS_URL + 'api/re-publish';

/** Comments */
export const addNewComments = WORKFLOWS_URL + 'api/comments/create';
export const getComments = WORKFLOWS_URL + 'api/comments/get';

/** Laundry external */
export const fetchBatch = WORKFLOWS_URL + 'api/external-laundry/manage';
export const createBatch = WORKFLOWS_URL + 'api/external-laundry/create';
export const addLaundryProducts = WORKFLOWS_URL + 'api/external-laundry-items/update';
export const removeLaundryProducts = WORKFLOWS_URL + 'api/external-laundry/delete';

/** Laundry internal */
export const createLaundry = WORKFLOWS_URL + 'api/internal-laundry/create';
export const FetchLaundry = WORKFLOWS_URL + 'api/internal-laundry/manage';

/** Photo Studio */
export const getMerchRacksUrl = WORKFLOWS_URL + 'api/renewal-records/merch-racks';
export const getMerchRackUrl = WORKFLOWS_URL + 'api/merch-racks';
export const managePhotoStudioUrl = WORKFLOWS_URL + 'api/renewal-records/photo-studio';
export const getPhotoStudioUrl = WORKFLOWS_URL + 'api/photo-studio/view';
export const photoStudioSaveUrl = WORKFLOWS_URL + 'api/photo-studio/update';

/** Photo Studio */
export const managePhotoEditingUrl = WORKFLOWS_URL + 'api/renewal-records/photo-editing';
export const getPhotoEditingUrl = WORKFLOWS_URL + 'api/photo-editing/view';
export const photoEditingSaveUrl = WORKFLOWS_URL + 'api/photo-editing/update';

/** Reports */
export const getShipmentReports = WORKFLOWS_URL + 'api/shipment-reports';
export const getShipmentBPReports = SERVICE_URL + 'service/b-master-data/shipment-reports-brandPartner'
export const getPublishedRecords = WORKFLOWS_URL + 'api/shipment-reports';
export const getHoldRecords = WORKFLOWS_URL + 'api/hold-items/get';
export const getSortReports = WORKFLOWS_URL + 'api/sort-reports/get';
/** Barcode scanner */
export const getScannedItem = WORKFLOWS_URL + 'api/scanned-item/get';
/** Moving item to any stage */
export const moveItemToAnyStage = WORKFLOWS_URL + 'api/move-item/stage';
/**Time tracker overview */
export const getTimeTrackerDetails = WORKFLOWS_URL + 'api/time-tracker/fetch';
/**Checking shipments status*/
export const checkBpArchiveStatus = WORKFLOWS_URL + "api/workflow/archive-brandpartner";

/** Terminate item */
export const terminateItem = WORKFLOWS_URL + "api/item-terminate";

/** print label */
export const printLabel = SERVICE_URL + 'service/b-webconnector/print-label'
