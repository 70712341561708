import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { APICALL } from '../../../services/ApiServices';
import { t } from "../../../pages/Translations/TranslationUtils";
import Layout from "../../../pages/Shipment/Layout";
import ScreenDisable from '../../../utils/ScreenDisable';
import CommonShipmentServices from "../../../services/CommonShipmentServices";
import { statevalue } from './State';
import { WorkflowStageNames } from "../../../utils/constants/WorkflowStageNames";
import { ConfigConstants } from '../../../pages/Shipment/Constants/ShipmentConstants';
import RenewalRecordLeftSideOrganism from './RenewalRecordLeftSideOrganism';
import { createMerchandising, fetchList, fetchRenewalRecord } from '../../../routes/ApiEndPoints';
import RenewalRecordRightSideOrganism from './RenewalRecordRightSideOrganism';
import ActionButtonGroup from '../../molecules/ActionButtonGroup';
import { CommonConstants } from '../../../pages/Shipment/Constants/ShipmentConstants';
import { getMediaLibraries } from '../../../services/MediaLibraryFolderServices';
import FormValidation from '../../../services/FormValidation';
import RenewalRecordPopupsOrganism from './RenewalRecordPopupsOrganism';
import Popup from '../../../utils/popup';
import MediaLibrary from '../../../pages/MediaLibrary/MediaLibrary';
import styles from "../../organisms/organisms.module.css";
import customAlert from '../../atoms/CustomAlert';
import CommonServices from '../../../services/CommonServices';
import { RenewalServices } from '../../../services/RenewalServices';
import WorkflowItemInfo from '../../molecules/WorkflowItemInfo';
import useUpdateInactivityTimeoutService from '../../../services/useUpdateInactivityTimeoutService';
import { useInactivityTimeoutContext } from '../../../services/useInactivityTimeoutContext';

interface RecordsOverviewOrganismProps {
  rejected?: boolean,
  view?: boolean,
  bpid?: any,
  returnurl?: string
}

const RecordsOverviewOrganism: React.FC<RecordsOverviewOrganismProps> = ({
  rejected = false, view = false, bpid, returnurl
}) => {

  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const renewal_id = urlParams.get("edit");
  const localdata = CommonShipmentServices.getPostData();
  bpid = bpid ? bpid : localdata.brand_partner_id;
  const bpdetails = JSON.parse(localStorage.getItem('bp_details') ?? '{}');
  const [showMedia, setShowMedia] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [selectedIssueList, setSelectedIssueList] = useState<any>([]);
  const [totaltime, setTotalTime] = useState(0);
  const [timer, setTimer] = useState(true);
  const [issku, setIssku] = useState(false);
  const [configdata, setConfigData] = useState<any>([]);
  const [state, setState] = useState(statevalue);
  const [errors, setErrors] = useState({
    style_number: '',
    color: '',
    size: ''
  });
  const [popuptype, setPopupType] = useState('')
  const [commentData, setCommentData] = useState({
    type: "renewal_record",
    type_id: Number(renewal_id),
    stagename: "merchandising",
    commentdata: {},
  });
  const { updateTimeout } = useUpdateInactivityTimeoutService();
  const { inactivityTimeoutState } = useInactivityTimeoutContext();
  const [trackTime, setTrackTime] = useState<boolean>(false)

  useEffect(() => {
    setTrackTime(localStorage.getItem("trackTime") && localStorage.getItem("trackTime") == "yes" ? true : false)
  }, [localStorage.getItem("trackTime")]);

  useEffect(() => {
    if (trackTime) {
      updateTimeout("merchandising")
    }
  }, [trackTime]);

  const handleTimeoutNavigation = async () => {
    await timeTracker(false, Number(inactivityTimeoutState.redirectTime));
    navigate(inactivityTimeoutState.redirectUrl);
  };

  useEffect(() => {
    if (inactivityTimeoutState.timeoutRedirection) {
      handleTimeoutNavigation()
    }
  }, [inactivityTimeoutState.timeoutRedirection]);

  const setTimerRunning = (timer: boolean) => {
    setTimer(timer);
  };

  useEffect(() => {
    if (configdata.length == 0) {
      fetchRenewalRecordData();
    }
  }, []);

  const clearLocalStorageForView = () => {
    localStorage.removeItem("bp_details");
    localStorage.removeItem("bp_id");
    localStorage.removeItem("shipment_dashboard_url");
    localStorage.removeItem("shipment_details");
  }

  useEffect(() => {
    if (view === true) {
      clearLocalStorageForView();
    }
    if (!view && localdata?.user_id != null && commentData?.type_id != null && trackTime) {
      timeTracker(true);
      window.addEventListener('beforeunload', () => timeTracker());
      return () => {
        timeTracker();
        window.removeEventListener('beforeunload', () => timeTracker());
      }
    }
  }, [commentData?.type_id != null, localdata?.user_id != null]);

  useEffect(() => {
    const updateValues = async () => {
      if (state.colorlabel == '' && state.sizelabel == ''
        && configdata.bpdata != null && state.serial_number != "") {
        let imageurls: any = await getImageUrls();
        setState({
          ...state,
          colorlabel: CommonShipmentServices.getFilteredTitle(configdata.bpdata.color ?? [], 'value', state.color, 'label'),
          sizelabel: CommonShipmentServices.getFilteredTitle(configdata.bpdata.size ?? [], 'value', state.size, 'label'),
          product_type_title: CommonShipmentServices.getFilteredTitle(configdata.bpdata.product_type ?? [], 'value', state.product_type, 'label'),
          product_group_title: CommonShipmentServices.getFilteredTitle(configdata.bpdata.product_group ?? [], 'value', state.product_group, 'label'),
          merchandise_image_url: imageurls
        })
      }
    }
    updateValues()
  }, [configdata, state]);

  const getImageUrls = async () => {
    let productimage: any = [];
    let imageids: any = state.merchandise_image;
    try {
      if ((Array.isArray(imageids) && imageids !== undefined && imageids.length > 0) && imageids != null) {

        let librarydata = await getMediaLibraries({
          medialibrary_id: imageids
        })

        let files: any = Object.values(librarydata)?.filter((item: any) =>
          imageids.includes(item?.medialibrary_id)
        );
        productimage = files?.map((item: any) => item.url);
      } else if (state.product_type != null) {
        let product = configdata?.ProductType?.find((data: any) => data?.product_type_id == state.product_type);
        if (product?.front_image != null && product?.back_image != null) {
          productimage = [product.front_image, product.back_image];
        }
      } else {
        productimage = [];
      }
    }
    catch (error) {
      console.log(error);
    }
    return productimage;
  }

  const fetchBPData = async (sku: string | null = null) => {
    try {
      let list = [
        ConfigConstants.ISSUESIZE,
        ConfigConstants.REPAIRASSESSMENT,
        ConfigConstants.ZONE,
        ConfigConstants.ENVIRONMENT,
        ConfigConstants.ISSUETYPE,
        ConfigConstants.WORKAREA,
        ConfigConstants.PRODUCTTIER,
        ConfigConstants.ALL_PRODUCTTYPE
      ]
      let postdata = {
        "method": "POST",
        "data": {
          "list": list,
          "bp_id": bpid,
          "sku": sku
        }
      }
      const response = await APICALL.service(fetchList, 'POST', postdata, true);
      if (response?.status && response?.status == 200) {
        setConfigData(response?.data)
        setIssku(response?.sku);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const fetchRenewalRecordData = async () => {
    try {
      let flow: string = '';
      if (view) {
        flow = 'reports';
      } else if (rejected) {
        flow = 'rejected';
      } else {
        flow = 'merchandising';
      }
      const postData = {
        id: renewal_id,
        flow: flow,
      };
      const response = await APICALL.service(fetchRenewalRecord, "POST", postData);
      if (response?.status == 200) {
        let data = response?.data;
        fetchBPData(data?.renewal_info.sku);
        if (flow != 'rejected' && response?.qaissues?.length > 0) {
          let qaissues = response?.qaissues[0]?.quality_assurance_issues;
          setSelectedIssueList(qaissues);
        }
        let imageids: any = data?.renewal_image?.map((item: any) => Number(item.image_id))
        setRejectionReason((data?.renewal_record_wms_info?.rejected_reason) ?? "")
        setSelectedIssueList(response?.qaissues?.[0]?.quality_assurance_issues);
        setCommentData({ ...commentData, commentdata: response?.commentdata });
        updateStateValues(data, imageids)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const updateStateValues = (data: any, imageids: any) => {
    setState((prevState) => ({
      ...prevState,
      renewal_id: renewal_id ?? '',
      serial_number: data?.serial_number,
      sku_number: data?.renewal_info?.sku,
      style_number: data?.renewal_product_info?.style,
      color: data?.renewal_product_info?.color,
      size: data?.renewal_product_info?.size,
      product_description: data?.renewal_info?.product_description,
      product_type: data?.renewal_product_info?.product_type,
      product_group: data?.renewal_product_info?.product_group,
      product_tier: data?.tier,
      merch_rack: data?.renewal_merch_rack_info?.merch_rack,
      old_merch_rack: data?.renewal_merch_rack_info?.merch_rack,
      clean_process: data?.renewal_product_info?.clean_process,
      gender: data?.renewal_product_info?.gender,
      dimension: data?.renewal_product_info?.dimension,
      weight: data?.renewal_product_info?.weight,
      merchandise_image: imageids,
      // merchandise_image_url: urls,
      fibre_content: data?.renewal_product_info?.fibre_content,
      updatedAt: data?.updatedAt,
      createdAt: data?.createdAt,
    }));
  }

  const timeTracker = async (reloaded: boolean = false, timeoutRedirection: number | null = null) => {
    let time = await CommonShipmentServices.saveTimerApi(
      'renewal',
      commentData?.type_id,
      localdata?.user_id,
      WorkflowStageNames.MERCHANDISING,
      reloaded,
      false,
      timeoutRedirection
    );
  }

  const handleSelectChange = (selectedValues: any, name: string) => {
    if (timer) {
      setState((prevFormData: any) => {
        const updatedFormData = {
          ...prevFormData,
          [name]: selectedValues != null ? selectedValues.value : null
        };
        if (selectedValues != null && (name === 'color' || name === 'size')) {
          updatedFormData[name + 'label'] = selectedValues.label;
        }
        return updatedFormData;
      });
    }
  };

  const handleInputChange = (e: any, field?: string) => {
    if (timer) {
      if (e != null) {
        const { name, value } = e.target ?? e;
        setState((prevFormData: any) => ({
          ...prevFormData,
          [field ?? name]: value,
        }));
      } else {
        field &&
          setState((prevFormData: any) => ({ ...prevFormData, [field]: null }));
      }
    }
  };

  const handlePopup = (name: string) => {
    timer && setPopupType(name)
  }

  const determineHandler = (type: any) => {
    if (type == "select") {
      return handleSelectChange;
    } else if (type == "buttonwithpopup") {
      return handlePopup;
    } else {
      return handleInputChange;
    }
  }

  const removeError = (fieldName: any) => {
    setErrors((prevFormData) => {
      if (!prevFormData) {
        return prevFormData;
      }
      return {
        ...prevFormData,
        [fieldName]: "",
      };
    });
  };

  const handleSubmit = () => {
    let data = {
      ...state,
      sku_number: RenewalServices.generateSkuNumber(state, issku)
    };
    const isvalid = RenewalServices.formValidate(data, errors, setErrors);
    const printername = localStorage.getItem('savedPrinter');

    if (isvalid === 'sku') {
      customAlert('error', t("The SKU being generated is longer than 50 characters"), 6000);
    }
    else if (isvalid === 'true' && !bpdetails.label_printing) {
      popupSubmitHandler("view_barcode", false);
    }
    else if (isvalid === 'true' && printername == null) {
      setPopupType('view_barcode');
    } else if (isvalid === 'true' && printername != null) {
      popupSubmitHandler("view_barcode", false, { inputValue: printername });
    }
  };

  const handleBackbuttonClick = () => {
    if (returnurl) {
      navigate(returnurl)
    } else if (rejected) {
      navigate('/publish/rack?id=' + state.old_merch_rack + '&tab=rejected')
    }
  }

  const popupSubmitHandler = async (type: any, status: boolean, data?: any) => {
    if (type !== 'view_barcode') setPopupType('');
    if (type === 'view_barcode') {
      const excludebpids: any = process.env.REACT_APP_EXCLUDE_IDS;
      if (excludebpids != undefined && excludebpids.includes(bpid)) {
        data = ''
      }
      data ? (data === 'close' ? setPopupType('') : handlePrint(data)) : setPopupType('merch_rack');
    } else if (type == 'merch_rack' && status == true) {
      try {
        if (state.merch_rack == null) {
          customAlert(
            "error",
            t("Please select the merch rack"),
            6000
          )
        } else {
          const postData = {
            ...state,
            ...localdata,
            merchandise_status: false,
            rejected: rejected,
            sku_number: RenewalServices.generateSkuNumber(state, issku)
          };

          const response = await APICALL.service(
            createMerchandising,
            "POST",
            postData
          );
          if (response?.status === 200) {
            if (rejected) {
              navigate('/publish/rack?id=' + state.old_merch_rack + '&tab=rejected')
            } else {
              navigate("/merchandising/manage");
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  const handlePrint = async (data: any) => {
    try {
      const postdata = {
        "printerName": data?.inputValue.trim(),
        "labelType": "SKU",
        "labelData": {
          "style": state.style_number,
          "skuId": (RenewalServices.generateSkuNumber(state, issku)).toUpperCase(),
          "description": (state.product_description && state.product_description !== "") ? state.product_description : `${state.style_number}-${state.colorlabel}.${state.sizelabel}`,
          "serialNumber": state.serial_number
        }
      }
      let error: any = validatepostdata(postdata);

      if (!error) {
        let res: any = await CommonServices.printLabelApi(postdata);
        if (res?.type === "success") {
          localStorage.setItem('savedPrinter', data?.inputValue.trim());
        }
        res?.message && customAlert(res?.type, t(res?.message), 6000);
        if (res.statusCode === 201) {
          setPopupType('merch_rack');
        }
      } else {
        let errortext = Object.keys(error)?.filter(key => error[key] !== "").map(key => `<div>${t(error[key])}</div>`).join("");
        errortext && customAlert('error', errortext, 6000);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const validatepostdata = (data: any) => {
    let error = {
      "printerName": FormValidation.nameValidation(data?.printerName, 255, 'Printer name'),
      "skuId": FormValidation.nameValidation(data?.labelData?.skuId, 50, 'SKU id'),
      "style": FormValidation.nameValidation(data?.labelData?.style, 15, 'Style number'),
      // "description": FormValidation.nameValidation(data?.labelData?.description, 80, 'Description'),
      "serialNumber": FormValidation.nameValidation(data?.labelData?.serialNumber, 8, 'Serial number'),
    }
    return Object.values(error)?.some(item => item !== "") ? error : false;
  }

  const getImagesFromPopup = (modalimages: any) => {
    const mediaUrls = modalimages.map((item: any) => item.media_url);
    const mediaIds = modalimages.map((item: any) => item.media_id);
    setState((prevState: any) => ({
      ...prevState,
      merchandise_image: mediaIds,
      merchandise_image_url: mediaUrls,
    }));
    setShowMedia(false);
  };

  return (
    <Layout
      pagename={
        view
          ? t("Overview") + (configdata?.bpdata?.bpdata?.title ? ": " + configdata?.bpdata?.bpdata?.title : "")
          : (rejected
            ? t('Publishing')
            : t("Merchandising"))
      }
      logo={view ? configdata?.bpdata?.bpdata?.file_path ?? true : false}
      timer={!view}
      comments={view ? undefined : commentData}
      hold={!view}
      ongoing={trackTime}
      totaltime={totaltime}
      stage={WorkflowStageNames.MERCHANDISING}
      setTimerRunning={setTimerRunning}
    >

      <WorkflowItemInfo serialNumber={state?.serial_number}
        productType={state?.product_type_title}
        productDescription={state?.product_description}
        sku={RenewalServices.generateSkuNumber(state, issku)}
        color={state?.colorlabel}
        size={state?.sizelabel}
      />

      {configdata.bpdata && configdata.bpdata != null && state.serial_number != "" &&
        <div className="flex-1 overflow-auto mx-2 px-1">
          <div className='row position-relative m-0 h-100'>
            <ScreenDisable display="none" />
            <RenewalRecordLeftSideOrganism
              serialnumber={state.serial_number}
              merchandiseimageurl={state.merchandise_image_url}
              dataentry={state}
              view={view}
              rejected={rejected}
              rejectionReason={rejectionReason}
              setShowMedia={setShowMedia}
              timer={timer}
              configdata={configdata}
              selectedIssueList={selectedIssueList}
            />
            <RenewalRecordRightSideOrganism
              configdata={configdata}
              state={state}
              determineHandler={determineHandler}
              removeError={removeError}
              formerrors={errors}
              view={view}
              issku={issku}
            />
          </div>
        </div>
      }
      <ActionButtonGroup
        backTitle={t(CommonConstants.BACK)}
        saveAndNextTitle={view ? "" : (rejected ? t("Save & Move to Publishing") : (bpdetails.label_printing ? t(CommonConstants.SAVE_AND_SCAN_NEXT) : t(CommonConstants.SAVE_AND_NEXT)))}
        handleBackClick={handleBackbuttonClick}
        handleSaveAndNextClick={handleSubmit}
        saveDisabled={false}
        backbuttoncss="back-btn"
        maindivcss="d-flex justify-content-between mt-2"
      />
      <RenewalRecordPopupsOrganism
        state={state}
        setState={setState}
        popuptype={popuptype}
        configdata={configdata}
        popupSubmitHandler={popupSubmitHandler}
      />
      {showMedia && (
        <Popup
          body={
            <MediaLibrary
              modal={true}
              handleImagePopupSubmit={getImagesFromPopup}
              closePopup={() => setShowMedia(false)}
              selectedImages={
                state.merchandise_image?.map((media_id, index) => ({
                  media_id: media_id,
                  media_url: state.merchandise_image_url[index],
                })) ?? []
              }
            />
          }
          cancel={() => setShowMedia(false)}
          title={t("Media library")}
          modalclass={`${styles.medailibrarypop}`}
        />
      )}
    </Layout>
  )
}

export default RecordsOverviewOrganism;