import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./masterdata.module.css";
import Archive from "../../static/icons/Archive";
import Unarchive from "../../static/icons/Unarchive";
import Edit from "../../static/icons/Edit";
import Popup from "../../utils/popup";
import { t } from "../Translations/TranslationUtils";
import customAlert from "../../components/atoms/CustomAlert";
import Pagination from "../../services/Paginations";
import { FaSort } from "react-icons/fa";
import LabelField from "../../components/atoms/LabelField";
import CommonServices from "../../services/CommonServices";
import EncryptDecryptService from "../../services/EncryptDecryptService";
import { APICALL } from "../../services/ApiServices";
import {
  archieveConfigData,
  fetchConfigData,
  fetchTablesUrl,
} from "../../routes/ApiEndPoints";
import { StylesConfig } from "react-select";
import AccessControl from "../../services/AccessControl";
import MultiSelect from "../../components/atoms/MultiSelectField";
import Button from "../../components/atoms/Button";
import InputTextfield from "../../components/atoms/InputTextField";
import { CommonConstants } from "../Shipment/Constants/ShipmentConstants";
import { ConfigDataHelpTexts } from "../../utils/constants/ConfigDataHelpTexts";
import { useUserContext } from "../../routes/Contextlib";
import WindowHeightComponent from "../../commonComponents/WindowHeightComponent";
interface SearchInput {
  subtype: string;
  popup: any;
}

type FetchedData = {
  [key: string]: {
    title: string;
    type: string;
    status: boolean;
  };
};

interface Option {
  value: string;
  label: string;
}

const ManageMasterData: React.FC = () => {
  const { user, setUser } = useUserContext();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const type: string = urlParams.get('type') ?? "";
  const [searchinput, setSearchInput] = useState<SearchInput>({
    subtype: "",
    popup: false,
  });
  const [requiredPermissions, setRequiredPermissions] = useState<any>([])
  const [originaldata, setOriginalData] = useState<FetchedData>({});
  const [activeTab, setActiveTab] = useState<"active" | "inactive">("active");
  const [subtypeoptions, setSubtypeoptions] = useState<
    { value: string; label: string }[]
  >([]);

  const [searchText, setSearchText] = useState("");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [isSorted, setIsSorted] = useState(false); // New state variable to track sorting
  const [statusChange, setStatusChange] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const filteredData = Object.values(originaldata).filter((value: any) => {
    const title = value.title ? value.title.toLowerCase() : "";
    const searchTextLower = searchText.toLowerCase();
    return (
      ((activeTab === "active" && value.status === true) ||
        (activeTab === "inactive" && value.status === false)) &&
      title.includes(searchTextLower)
      // || type.includes(searchTextLower)
    );
  });

  const sortedData = isSorted
    ? filteredData.slice().sort((a: any, b: any) => {
      const usernameA = a.title.toLowerCase();
      const usernameB = b.title.toLowerCase();

      if (sortOrder === "asc") {
        if (usernameA < usernameB) return -1;
        if (usernameA > usernameB) return 1;
        return 0;
      } else {
        if (usernameA > usernameB) return -1;
        if (usernameA < usernameB) return 1;
        return 0;
      }
    })
    : filteredData;

  // Calculate the indexes of the users to be displayed on the current page
  const totalData = sortedData.length;
  const totalPages = Math.ceil(totalData / itemsPerPage);
  const validPageNumber = currentPage > totalPages ? totalPages : currentPage;
  const indexOfLastData = validPageNumber * itemsPerPage;
  const indexOfFirstData = indexOfLastData - itemsPerPage;
  const currentData = sortedData.slice(indexOfFirstData, indexOfLastData);

  useEffect(() => {
    fetchTables();
  }, []);

  useEffect(() => {
    const storageMessage = localStorage.getItem("errorAlertMessage");
    if (storageMessage) {
      localStorage.removeItem("errorAlertMessage");
      customAlert("error", storageMessage, 6000);
    }
  }, []);

  useEffect(() => {
    const storageMessage = localStorage.getItem("successAlertMessage");
    if (storageMessage) {
      localStorage.removeItem("successAlertMessage");
      customAlert("success", storageMessage, 6000);
    }
  }, []);

  useEffect(() => {
    if (refresh && type !== "") {
      setSearchInput({ ...searchinput, subtype: type });
      const url = new URL(window.location.href);
      url.searchParams.delete('type');
      window.history.pushState(null, '', url.href);
    }
  }, [refresh]);

  useEffect(() => {
    fetchData(searchinput.subtype);
  }, [searchinput.subtype]);

  //To display alert message
  useEffect(() => {
    const storageMessage = localStorage.getItem("successAlertMessage");
    if (storageMessage) {
      localStorage.removeItem("successAlertMessage");
      customAlert("success", storageMessage, 6000);
    }
  });

  const fetchTables = async (model: string | null = null) => {
    try {
      const postData = {
        // Encrypting the request data
        method: "GET",
        data: EncryptDecryptService.encryptData(JSON.stringify({})),
      };
      const response = await APICALL.service(fetchTablesUrl, "POST", postData);
      let decrypteddata = JSON.parse(
        EncryptDecryptService.decryptData(response)
      );

      const subtypeOption = Object.values(decrypteddata)
        .map((item: any) => ({
          value: item,
          label: CommonServices.capitalizeLabel(item),
        }));
      subtypeOption.sort((a, b) => a.label.localeCompare(b.label));
      setSubtypeoptions(subtypeOption);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async (model: string | null = null) => {
    try {
      const postData = {
        method: "POST",
        data: {
          type: model ? "model" : "all",
          name: model ? CommonServices.convertIntoModelName(model) : "all",
          filterConditions: [
            { table_name: "cd_merch_rack", column: 'title', condition: '!=', value: 'Recycle' },
          ]
        }
      };

      const response = await APICALL.service(fetchConfigData, "POST", postData, true);
      if (response?.status !== 200) {
        throw new Error("Request failed");
      }
      if (response.status === 200) {
        const responseData = response.data as FetchedData;
        setOriginalData(responseData);
      }
      setRefresh(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSortClick = () => {
    !isSorted && setIsSorted(true);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const handlePaginationClick = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleCreate = () => {
    navigate(`/master-data/create?form=${searchinput.subtype}`);
  };

  const handleEdit = (value: any) => {
    let variable = value.type + "_id";
    navigate(
      `/master-data/create?form=${value.type}&id=${value[variable]}&search=${searchinput.subtype != ''}`
    );
  };

  const handleArchiveOrActive = async (value: any) => {
    let variable = value.type + "_id";
    try {
      const postData = {
        // Encrypting the request data
        method: "POST",
        data: EncryptDecryptService.encryptData(
          JSON.stringify({
            model:
              value &&
              CommonServices.convertIntoModelName(value.type ?? "ProductGroup"),
            id: value[variable],
          })
        ),
      };
      const response = await APICALL.service(
        archieveConfigData,
        "POST",
        postData
      );
      let decrypteddata = JSON.parse(
        EncryptDecryptService.decryptData(response)
      );

      if (decrypteddata?.status !== 200) {
        throw new Error("Request failed");
      }
      if (decrypteddata.status === 200) {
        let message: string = t(`Status changed successfully`);
        localStorage.setItem("successAlertMessage", message);
        (activeTab === 'inactive') ? setActiveTab("active") : setActiveTab("inactive");
        setStatusChange(!statusChange);
        setSearchInput({ ...searchinput, popup: false });
        fetchData(searchinput.subtype);
      }
    } catch (error) {
      let message: string = `Error while changing status`;
      localStorage.setItem("errorAlertMessage", message);
      setStatusChange(!statusChange);
    }
  };

  const handleTabClick = (tab: "active" | "inactive") => {
    setActiveTab(tab);
    setCurrentPage(1);
  }

  const goToPreviousPage = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    let backButtonURL: any = localStorage.getItem("backbuttonURL");
    localStorage.removeItem("backbuttonURL");
    navigate(backButtonURL ?? "/");
  };

  // option custom colors starts
  const colourStyles: StylesConfig = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // Remove references to data.color since color property is removed from customOptions
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected || isFocused
            ? "#d20c14" // Red background when selected or focused
            : undefined,
        color: isDisabled
          ? "black" // Default text color when disabled
          : isSelected || isFocused
            ? "white" // White text color when selected or focused
            : "black", // Default text color when not selected or focused
        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
  };
  // option custom colors ends

  useEffect(() => {
    const userPerm: any = user?.permissions;
    const truePerm: any = userPerm?.find((item: any) => item.permission.toLowerCase().trim() === "master data")

    let permissions = [
      {
        permission: "master data",
        read: true,
      }
    ]

    if (truePerm?.create === true || truePerm?.update === true || truePerm?.delete === true) {
      permissions[0].read = false;
    }

    setRequiredPermissions(permissions)
  }, [user != null])

  return (
    <AccessControl
      requiredPermissions={requiredPermissions}
      renderNoAccess={true}
    >
      <div className="row">
        {searchinput.popup && (
          <Popup
            title={t("Confirmation")}
            body={t(`Are you sure you want to ${searchinput.popup.status ? "deactivate" : "activate"
              } this data?`)}
            cancel={() => setSearchInput({ ...searchinput, popup: false })}
            submit={() => handleArchiveOrActive(searchinput.popup)}
            yestext={t(CommonConstants.YES)}
            notext={t(CommonConstants.NO)}
          />
        )}
        <div className="col-md-11 ms-auto px-4 ">
          <WindowHeightComponent>
            <div className="d-flex flex-column overflow-auto h-100">
              <div className="py-xxl-4 py-xl-3 py-lg-3 page-title top-0">
                {t("Manage master data")}
              </div>
              <div className="flex-1 overflow-auto d-flex flex-column h-100">
                <div className={`py-2 ${styles.masterselect}`}>
                  <LabelField title={t("Select data")} />
                  <div className="d-flex justify-content-between">
                    <div className="d-flex flex-wrap gap-4">
                      <MultiSelect
                        isClearable={true}
                        className={`shadow-none ${styles.masterdata_input}`}
                        classNamePrefix="select"
                        placeholder={t("Type")}
                        styles={colourStyles}
                        options={subtypeoptions}
                        standards={subtypeoptions?.find(
                          (option) => option.value === searchinput.subtype
                        )}
                        handleChange={(e: any) => {
                          setSearchInput({ ...searchinput, subtype: e?.value ?? '' });
                          setSearchText('');
                        }}
                      />
                      <AccessControl
                        requiredPermissions={[
                          {
                            permission: "master data",
                            create: true,
                          },
                        ]}
                      >
                        {searchinput.subtype && (
                          <Button
                            title={t("Create")}
                            className="submit-btn shadow-none h-100 px-4"
                            handleClick={handleCreate}
                          />
                        )}
                      </AccessControl>
                    </div>
                    <div className="">
                      <InputTextfield
                        className={`form-control h-100 pe-5 shadow-none ${styles.masterdata_input}`}
                        placeholder={t('Search by name')}
                        value={searchText}
                        handleChange={(e) => setSearchText(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className={`${styles.helptextdiv}`}>
                  {searchinput.subtype !== '' && ConfigDataHelpTexts[searchinput.subtype] !== '' &&
                    (<span className='custom_astrick_before mb-3 fw-bold fst-italic'> {t(ConfigDataHelpTexts[searchinput.subtype])}</span>)
                  }
                </div>
                <div className="user-tabs my-2">
                  <div className="">
                    <button
                      className={`${activeTab === "active" ? "active" : ""
                        } border-0 bg-transparent tab-btn me-4`}
                      onClick={() => handleTabClick("active")}
                    >
                      {t("Active")}
                    </button>
                    <button
                      className={`${activeTab === "inactive" ? "active" : ""
                        } border-0 bg-transparent tab-btn`}
                      onClick={() => handleTabClick("inactive")}
                    >
                      {t("Inactive")}
                    </button>
                  </div>
                </div>
                <div className="flex-1 overflow-auto">
                  <table className="table">
                    <thead className="position-sticky top-0">
                      <tr>
                        <th className="border-bottom-0 w-50">
                          {t("Name")}
                          <button
                            onClick={handleSortClick}
                            className="sort-btn border-0 bg-transparent text-gray"
                          >
                            <FaSort />
                          </button>
                        </th>
                        <th className="border-bottom-0 w-25 text-center">{t("Type")}</th>
                        <AccessControl
                          requiredPermissions={[
                            {
                              permission: "master data",
                              update: true,
                              delete: true,
                            },
                          ]}
                          strict={false}
                          actions={true}
                        >
                          <th className="border-bottom-0 text-center w-25">
                            {t("Actions")}
                          </th>
                        </AccessControl>
                      </tr>
                    </thead>
                    <tbody>
                      {currentData.length ? Object.entries(currentData).map(([key, value]) => (
                        <tr key={key}>
                          <td className="text-break">{value.title ?? ""}</td>
                          <td className="text-break text-center">
                            {CommonServices.capitalizeLabel(
                              value?.type ?? "Product group"
                            )}
                          </td>
                          <AccessControl
                            requiredPermissions={[
                              {
                                permission: "master data",
                                update: true,
                                delete: true,
                              },
                            ]}
                            strict={false}
                            actions={true}
                          >
                            <td className="text-break manage-users-action text-center">
                              <AccessControl
                                requiredPermissions={[
                                  {
                                    permission: "master data",
                                    update: true,
                                  },
                                ]}
                              >
                                <button
                                  className="me-4 border-0 bg-transparent shadow-none px-0"
                                  onClick={() => handleEdit(value)}
                                  title={t('Edit')}
                                >
                                  <Edit />
                                </button>
                              </AccessControl>

                              <AccessControl
                                requiredPermissions={[
                                  {
                                    permission: "master data",
                                    delete: true,
                                  },
                                ]}
                              >
                                <button
                                  className="me-4 border-0 bg-transparent shadow-none manage-users-action px-0"
                                  onClick={() =>
                                    setSearchInput({ ...searchinput, popup: value })
                                  }
                                  title={value.status ? t('Deactivate') : t('Activate')}
                                >
                                  {value.status ? <Archive /> : <Unarchive />}
                                </button>
                              </AccessControl>
                            </td>
                          </AccessControl>
                        </tr>
                      )) :
                        <td colSpan={3} className="text-center text-danger">
                          {t(CommonConstants.NO_RESULTS_FOUND)}
                        </td>
                      }
                    </tbody>
                  </table>
                </div>
              </div>


              <div className="row mt-2 m-0 position-relative">
                {sortedData.length > itemsPerPage && totalPages > 1 && (
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePaginationClick={handlePaginationClick}
                  />
                )}
                <div className="col-6 p-0">
                  <button
                    className="back-btn shadow-none float-start text-decoration-none"
                    onClick={() => navigate("/")}
                  >
                    {t(CommonConstants.BACK)}
                  </button>
                </div>
              </div>
            </div>
          </WindowHeightComponent>
        </div>
      </div>
    </AccessControl>
  );
};

export default ManageMasterData;
