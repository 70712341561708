import React, {
  useState,
  useEffect
} from "react";
import Layout from "../../pages/Shipment/Layout";
import { t } from "../../pages/Translations/TranslationUtils";
import { APICALL } from "../../services/ApiServices";
import {
  createFinishing,
  fetchConfigData,
  fetchEach,
  getFinishing,
} from "../../routes/ApiEndPoints";
import AdminCard from "../molecules/AdminDashBoardCard";
import { useNavigate } from "react-router-dom";
import Button from "../atoms/Button";
import CommonShipmentServices from "../../services/CommonShipmentServices";
import { WorkflowStageNames } from "../../utils/constants/WorkflowStageNames";
import styles from "../organisms/organisms.module.css"
import ScreenDisable from "../../utils/ScreenDisable";
import { CommonConstants } from "../../pages/Shipment/Constants/ShipmentConstants";
import { useUserContext } from "../../routes/Contextlib";
import CommonServices from "../../services/CommonServices";
import WorkflowItemInfo from "../molecules/WorkflowItemInfo";
import useUpdateInactivityTimeoutService from "../../services/useUpdateInactivityTimeoutService";
import { useInactivityTimeoutContext } from "../../services/useInactivityTimeoutContext";

const CreateFinishingOrganism: React.FC = () => {
  const navigate = useNavigate();
  const localdata = CommonShipmentServices.getPostData();
  const { user } = useUserContext();
  const urlParams = new URLSearchParams(window.location.search);
  const [finishingData, setFinishingData] = useState<any>();
  const [configData, setConfigData] = useState();
  const [selectedData, setSelectedData] = useState<Number[]>([]);
  const renewalid = urlParams.get("edit");
  const [totaltime, setTotalTime] = useState(0);
  const [timer, setTimer] = useState(true);
  const [dataentry, setDataEntry] = useState<any>({})
  const [commentData, setCommentData] = useState({
    type: "renewal_record",
    type_id: null,
    stagename: "finishing",
    commentdata: {}
  });
  const [longestTitle, setLongestTitle] = useState<Number | null>(null)
  const [unloadLogic, setUnloadLogic] = useState<boolean>(true)
  const [trackTime, setTrackTime] = useState<boolean>(false)
  const { updateTimeout } = useUpdateInactivityTimeoutService();
  const { inactivityTimeoutState } = useInactivityTimeoutContext();

  useEffect(() => {
    setTrackTime(localStorage.getItem("trackTime") && localStorage.getItem("trackTime") == "yes" ? true : false)
  }, [localStorage.getItem("trackTime")]);
  
  useEffect(() => {
    if (trackTime) {
      updateTimeout("finishing")
    }
  }, [trackTime]);

  const handleTimeoutNavigation = async () => {
    await timeTracker(false, Number(inactivityTimeoutState.redirectTime));
    navigate(inactivityTimeoutState.redirectUrl);
  };

  useEffect(() => {
    if (inactivityTimeoutState.timeoutRedirection) {
      handleTimeoutNavigation()
    }
  }, [inactivityTimeoutState.timeoutRedirection]);

  const getConfigData = async () => {
    try {
      const postData = {
        method: "POST",
        data: {
          type: "model",
          name: "FinishingSteps",
        },
      };
      const response: any = await APICALL.service(
        fetchConfigData,
        "POST",
        postData,
        true
      );

      const activeData = response?.data?.filter((value: any) => value?.status == true);
      const longestTitleLength = activeData.reduce(
        (maxLength: number, item: any) => Math.max(maxLength, item.title.length),
        0
      );
      const multiplicator = calculateMultiplicator(longestTitleLength);
      const calculatedHeight = longestTitleLength * (multiplicator - 0.4);
      const minHeight = calculatedHeight > 300 ? calculatedHeight : 300;
      setLongestTitle(minHeight);
      setConfigData(CommonServices.sortAlphabattically(activeData));
    } catch (error) {
      console.log(error);
    }
  };

  const calculateMultiplicator = (textLength: number): number => {
    const maxLength = 255;
    const minLength = 1;
    const maxMultiplicator = 6;
    const minMultiplicator = 2;
    return (
      maxMultiplicator -
      ((textLength - minLength) / (maxLength - minLength)) *
      (maxMultiplicator - minMultiplicator)
    );
  };

  const fetchData = async () => {
    try {
      const postData = {
        renewal_id: renewalid,
      };
      const response = await APICALL.service(getFinishing, "POST", postData);
      if (response?.status === 200) {
        const datarequest = {
          "method": "POST",
          "data": {
            data: response?.data
          }
        }
        const dataentryres = await APICALL.service(fetchEach, 'POST', datarequest, true);

        if (dataentryres?.status == 200) {
          setDataEntry(dataentryres?.data);
        }

        setCommentData({ ...commentData, type_id: response?.data?.renewal_id, commentdata: response?.commentdata });
        setFinishingData(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const saveFinishing = async () => {
    try {
      const postData = {
        renewal_id: renewalid,
        serial_number: finishingData?.serial_number,
        ...localdata
      };

      const response = await APICALL.service(createFinishing, "POST", postData);
      if (response.status == 200) {
        navigate("/finishing/manage");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user != null && commentData?.type_id != null && trackTime) {
      timeTracker(true);
      window.addEventListener('beforeunload', () => timeTracker(false));
      return () => {
        timeTracker(false);
        window.removeEventListener('beforeunload', () => timeTracker(false));
      }
    }
  }, [commentData?.type_id != null, user != null, trackTime]);

  const timeTracker = async (reloaded: boolean = false, timeoutRedirection: number | null = null) => {
    let time = await CommonShipmentServices.saveTimerApi(
      'renewal',
      commentData?.type_id,
      user?.user_id,
      WorkflowStageNames.FINISHING,
      reloaded,
      false,
      timeoutRedirection
    );
  }

  useEffect(() => {
    getConfigData();
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const handleClick = (item: any) => {
    if (timer) {
      if (selectedData.includes(item.finishing_steps_id)) {
        const updatedArray = selectedData.filter(
          (id: any) => id != item.finishing_steps_id
        );
        setSelectedData(updatedArray);
      } else {
        const updatedArray = [...selectedData, item.finishing_steps_id];
        setSelectedData(updatedArray);
      }
    }
  };

  const handleSubmit = () => {
    saveFinishing();
  };

  const setTimerRunning = (timer: boolean) => {
    setTimer(timer);
  }

  return (
    <Layout
      pagename={("Finishing")}
      logo={true}
      timer={true}
      comments={commentData}
      hold={true}
      terminate={"/finishing/manage"}
      ongoing={trackTime}
      totaltime={totaltime}
      stage={WorkflowStageNames.FINISHING}
      setTimerRunning={setTimerRunning}
    >
      <WorkflowItemInfo serialNumber={dataentry?.renewal_product_info?.serial_number}
        productType={dataentry?.renewal_product_info?.product_type_title}
        productDescription={finishingData?.renewal_info?.product_description}
        sku={finishingData?.renewal_info?.sku}
        color={dataentry?.renewal_product_info?.color_title || dataentry?.renewal_product_info?.colorlabel}
        size={dataentry?.renewal_product_info?.size_title || dataentry?.renewal_product_info?.sizelabel} />

      <div className={`${styles.finising_body} flex-1 overflow-auto`}>
        {/* <div className={`text-gray text-break ${styles.page_title}`} title={t('serial number')}>
          {finishingData?.serial_number}
        </div> */}
        <div className="d-flex flex-wrap gap-5 max-content-height position-relative px-2 justify-content-center mt-5">
          <ScreenDisable display="none" />
          {configData &&
            Object.values(configData)?.map((item: any) => {
              return (
                <AdminCard
                  title={item?.title}
                  className={
                    ` ${selectedData.includes(item.finishing_steps_id)
                      ? "border-danger  text-red"
                      : ""} ${styles.finishingcard}`
                  }
                  mainclassName={styles.finishing_card_outer}
                  handleClick={() => handleClick(item)}
                  mainDivStyle={{ width: longestTitle, minWidth: longestTitle }}
                  innerDivStyle={{ width: longestTitle, minWidth: longestTitle, minHeight: longestTitle, height: longestTitle }}
                // titleStyle={{ padding: 5 }}
                />
              );
            })}
        </div>
      </div>
      <div className={`d-flex justify-content-between mt-2`}>
        <Button
          className="back-btn "
          title={t(CommonConstants.BACK)}
          handleClick={() => navigate("/finishing/manage")}
        />
        <Button
          title={t(CommonConstants.SAVE_AND_SCAN_NEXT)}
          handleClick={handleSubmit}
          className="submit-btn shadow-none"
        />
      </div>
    </Layout>
  );
};
export default CreateFinishingOrganism;
