import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Layout from '../Layout';
import CommonShipmentServices from '../../../services/CommonShipmentServices';
import RenewalRecordRightSideOrganism from '../../../components/organisms/RecordOverview/RenewalRecordRightSideOrganism';
import ImageCarousel from '../../../components/atoms/ImageCarousel';
import styles from "./dataentry.module.css"
import { Upload } from '../../../static/icons/Upload';
import { APICALL } from '../../../services/ApiServices';
import { createDataEntry, fetchList, fetchProductCatalogue } from '../../../routes/ApiEndPoints';
import { CommonConstants, DataEntryConstants } from '../Constants/ShipmentConstants';
import { WorkflowStageNames } from '../../../utils/constants/WorkflowStageNames';
import { t } from '../../Translations/TranslationUtils';
import Popup from '../../../utils/popup';
import MediaLibrary from '../../MediaLibrary/MediaLibrary';
import { errorstate, initialstate, initialvarstate } from './State';
import FormValidation from '../../../services/FormValidation';
import customAlert from '../../../components/atoms/CustomAlert';
import GenerateBarcodeMolecule from '../../../components/molecules/GenerateBarcodeMolecule';
import CommonServices from '../../../services/CommonServices';
import ActionButtonGroup from '../../../components/molecules/ActionButtonGroup';
import { RenewalServices } from '../../../services/RenewalServices';
import { useUserContext } from '../../../routes/Contextlib';
import ScreenDisable from '../../../utils/ScreenDisable';
import useUpdateInactivityTimeoutService from '../../../services/useUpdateInactivityTimeoutService';
import { useInactivityTimeoutContext } from '../../../services/useInactivityTimeoutContext';

const CreateDataEntry = () => {
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    const { user } = useUserContext();
    const localdata = CommonShipmentServices.getPostData();
    const bpdetails = JSON.parse(localStorage.getItem('bp_details') ?? '{}');
    const [state, setState] = useState(initialstate);
    const [varState, setVarState] = useState(initialvarstate);
    const [errors, setErrors] = useState(errorstate);
    const { updateTimeout } = useUpdateInactivityTimeoutService();
    const { inactivityTimeoutState } = useInactivityTimeoutContext();
    const [trackTime, setTrackTime] = useState<boolean>(false)

    useEffect(() => {
        setTrackTime(localStorage.getItem("trackTime") && localStorage.getItem("trackTime") == "yes" ? true : false)
    }, [localStorage.getItem("trackTime")]);

    useEffect(() => {
        if (trackTime) {
            updateTimeout("data_entry")
        }
    }, [trackTime]);

    const handleTimeoutNavigation = async () => {
        await timeTracker(false, Number(inactivityTimeoutState.redirectTime));
        navigate(inactivityTimeoutState.redirectUrl);
    };

    useEffect(() => {
        if (inactivityTimeoutState.timeoutRedirection) {
            handleTimeoutNavigation()
        }
    }, [inactivityTimeoutState.timeoutRedirection]);

    useEffect(() => {
        if (user != null) {
            if (id) {
                getSkuDetails();
            } else {
                fetchData();
            }
        }
        if (trackTime) {
            timeTracker(true);
            window.addEventListener('beforeunload', () => timeTracker());
            return () => {
                timeTracker();
                window.removeEventListener('beforeunload', () => timeTracker());
            }
        }
    }, [id, user]);

    const timeTracker = async (reloaded: boolean = false, timeoutRedirection: number | null = null) => {
        let time = await CommonShipmentServices.saveTimerApi(
            "bp",
            localdata?.brand_partner_id,
            user?.user_id,
            WorkflowStageNames.DATAENTRY,
            reloaded,
            false,
            timeoutRedirection
        );
    }

    const getSkuDetails = async () => { // getting the sku details the the sku is searched
        try {
            const postData = {
                method: "POST",
                data: {
                    id: id,
                    config: true,
                    ...localdata
                }
            };
            const response = await APICALL.service(fetchProductCatalogue, 'POST', postData, true);

            if (response?.status === 200) {
                let data = response?.data?.data[0];
                let disabled = DataEntryConstants.CATALOGUE_FIELDS.filter( // getting the fields which are to be disabled
                    key => (data[key] !== null && data[key] !== '' && data[key] !== undefined) // if the value is in API response, disabling the field
                );
                let selectdata: any = {};
                selectdata.bpdata = response?.data.bpdata;
                selectdata.disabledfields = disabled;
                let skuflag = disabled.includes('sku_number');
                setVarState((prevState: any) => ({ ...prevState, configdata: selectdata, issku: skuflag }));
                setState((prevState) => ({
                    ...prevState,
                    brand_partner_id: localdata?.brand_partner_id,
                    sku_number: data?.sku_number,
                    brand_partner: localdata?.bpname,
                    shipment: localdata?.shipment,
                    shipment_id: localdata?.shipment_id,
                    warehouse_location: bpdetails.warehouse_location,
                    product_catalogue_id: data?.product_catalogue_id,
                    product_description: data?.product_description,
                    gender: data?.gender_id !== null ?
                        data?.gender_id :
                        prevState.gender,
                    product_group: data?.product_group_id !== null ?
                        data?.product_group_id :
                        prevState.product_group,
                    style_number: data?.style_number,
                    product_type: data?.product_type_id !== null ?
                        data?.product_type_id :
                        prevState.product_type,
                    fibre_content: data?.fibre_content_id !== null ?
                        data?.fibre_content_id :
                        prevState.fibre_content,
                    weight: data?.weight,
                    color: data?.color_id,
                    colorlabel: data?.color_name,
                    size: data?.size_id,
                    sizelabel: data?.size
                }));
            }
        } catch (error) {
            console.log(error);
        }
    }

    const fetchData = async () => { // getting the field options if there is no SKU searched
        let postdata = {
            "method": "POST",
            "data": {
                "list": [],
                "bp_id": localdata?.brand_partner_id
            }
        }
        const response = await APICALL.service(fetchList, 'POST', postdata, true);
        if (response?.status && response?.status == 200) {
            let selectdata: any = {};
            selectdata.bpdata = response?.data?.bpdata;
            selectdata.disabledfields = [];

            setVarState((prevState: any) => ({
                ...prevState,
                configdata: selectdata
            }));
            setState((prevState: any) => ({
                ...prevState,
                brand_partner_id: localdata?.brand_partner_id,
                brand_partner: localdata?.bpname,
                shipment: localdata?.shipment,
                shipment_id: localdata?.shipment_id,
                warehouse_location: bpdetails?.warehouse_location
            }));
        }
    }

    const removeError = (fieldName: any) => { // on click remove error
        setErrors((prevFormData) => {
            if (!prevFormData) {
                return prevFormData;
            }
            return {
                ...prevFormData,
                [fieldName]: "",
            };
        });
    };

    const handleSelectChange = (selectedValues: any, name: string) => {
        if (varState.timerrunning) {
            setState((prevFormData: any) => {
                const updatedFormData = {
                    ...prevFormData,
                    [name]: selectedValues != null ? selectedValues.value : null
                };
                if (selectedValues != null && (name === 'color' || name === 'size')) {
                    updatedFormData[name + 'label'] = selectedValues.label;
                }
                return updatedFormData;
            });
        }
    };

    const handleInputChange = (e: any, field?: string) => {
        if (varState.timerrunning) {
            if (e != null) {
                const { name, value } = e.target ?? e;
                setState((prevFormData: any) => ({
                    ...prevFormData,
                    [field ?? name]: value,
                }));
            } else {
                field &&
                    setState((prevFormData: any) => ({ ...prevFormData, [field]: null }));
            }
        }
    };

    const determineHandler = (type: any) => {
        if (type == "select") {
            return handleSelectChange;
        } else {
            return handleInputChange;
        }
    }

    const getImagesFromPopup = (modalimages: any) => { // getting the image id from the media library popup
        const mediaUrls = modalimages?.map((item: any) => item.media_url);
        const mediaIds = modalimages?.map((item: any) => item.media_id);
        setState({ ...state, image_id: mediaIds, image_url: mediaUrls });
        setVarState({ ...varState, popup: false });
    }

    const saveDataEntry = async () => {// API call for saving data entry
        try {
            let postdata = {
                ...localdata,
                ...state,
                sku_number: RenewalServices.generateSkuNumber(state, varState.issku),
                predefinedIssueDetails: localStorage.getItem('predefinedIssueDetails')
                    ? JSON.parse(localStorage.getItem('predefinedIssueDetails') ?? '')
                    : null
            };

            const response = await APICALL.service(createDataEntry, 'POST', postdata);

            if (response?.status === 200) {
                if (bpdetails.label_printing) {
                    setVarState((prevState: any) => ({
                        ...prevState,
                        viewBarcode: true,
                        barcode: true,
                        barcodeurl: response?.data[0],
                        barcode_id: response?.data[1],
                        nextflowcount: response?.res?.newPresentFlowCount > 0
                    }));
                } else {
                    const currentUrl = window.location.pathname;
                    if (currentUrl === "/data-entry/create") {
                        window.location.reload();
                    } else {
                        navigate("/data-entry/create");
                    }
                }
            } else {
                customAlert("error", t(response.message), 6000);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        let data = {
            ...state,
            sku_number: RenewalServices.generateSkuNumber(state, varState.issku)
        };
        let isValidated = RenewalServices.formValidate(data, errorstate, setErrors);
        if (isValidated === 'sku') {
            customAlert('error', t("The SKU being generated is longer than 50 characters"), 6000);
        } else if (isValidated === 'true') {
            saveDataEntry();
        }
    };

    const handlePrint = async (data: any) => { // label print API
        try {
            const postdata = {
                "printerName": data?.inputValue?.trim(),
                "labelType": "SerialNumber",
                "labelData": {
                    "serialNumber": varState?.barcode_id
                }
            }
            let error: any = validatepostdata(postdata);
            if (!error) {
                let res: any = await CommonServices.printLabelApi(postdata);
                res?.message && customAlert(res?.type, t(res?.message), 6000);
                if (res.statusCode === 201) {
                    setVarState((prev) => ({ ...prev, viewBarcode: false }));
                }
            } else {
                let errortext = Object.keys(error).filter(key => error[key] !== "").map(key => `<div>${t(error[key])}</div>`).join("");
                errortext && customAlert('error', errortext, 6000);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const validatepostdata = (data: any) => {
        let error = {
            "printerName": FormValidation.nameValidation(data?.printerName, 255, 'Printer name'),
            "serialNumber": FormValidation.nameValidation(data?.labelData?.serialNumber, 8, 'Serial number'),
        }
        return Object.values(error)?.some(item => item !== "") ? error : false;
    }

    const setTimerRunning = (timer: boolean) => {
        setVarState((prevState: any) => ({ ...prevState, timerrunning: timer }));
    }

    return (
        <Layout
            pagename={('Data entry')}
            logo={true}
            timer={true}
            stage={WorkflowStageNames.DATAENTRY}
            ongoing={!varState.barcode && trackTime}
            totaltime={varState.totaltime}
            setTimerRunning={setTimerRunning}
        >
            {varState.barcode ?
                <div className="d-flex flex-column flex-1 overflow-auto">
                    <GenerateBarcodeMolecule
                        popup={varState}
                        setPopup={setVarState}
                        handlePrint={handlePrint}
                        barcodeUrl={varState.barcodeurl}
                        dataentry
                    />
                </div> :
                <>
                    <div className="flex-1 overflow-auto position-relative">
                        <ScreenDisable display='none' />
                        <div className="h-100 overflow-auto">
                            <div className="d-flex " >
                                <div className="pe-2 w-25">
                                    <div className="h-100 rounded border ">
                                        <div className="py-2 px-3 text-center d-flex flex-column h-100">
                                            <div className={`mb-2 d-flex align-items-center ${styles.data_entry_left_block} position-relative data-entry-image-swiper flex-1`}>
                                                <ImageCarousel images={state.image_url} />
                                            </div>
                                            <div className={`text-gray my-2 text-break`}>
                                                {state?.product_description}
                                            </div>
                                            <span className={`${styles.add_btn} width_maxcontent m-auto mb-3`}
                                                onClick={() => setVarState((prevState: any) => ({ ...prevState, popup: true }))}
                                            >
                                                <Upload />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {varState.configdata &&
                                    <RenewalRecordRightSideOrganism
                                        className={'w-75'}
                                        state={state}
                                        configdata={varState.configdata}
                                        removeError={removeError}
                                        determineHandler={determineHandler}
                                        formerrors={errors}
                                        dataentry
                                        issku={varState.issku}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <ActionButtonGroup
                        backTitle={t(CommonConstants.BACK)}
                        saveAndNextTitle={
                            bpdetails.label_printing
                                ? t(CommonConstants.SAVE_AND_GENERATE_BARCODE)
                                : t(CommonConstants.SAVE_AND_NEXT)
                        }
                        handleBackClick={() => navigate(localStorage?.getItem('shipment_dashboard_url') ?? "/shipment-dashboard")}
                        handleSaveAndNextClick={handleSubmit}
                        saveDisabled={false}
                        backbuttoncss="back-btn"
                        maindivcss="d-flex justify-content-between mt-2"
                    />
                </>
            }
            {varState.popup && <Popup
                body={
                    <MediaLibrary
                        modal={true}
                        handleImagePopupSubmit={getImagesFromPopup}
                        closePopup={() => setVarState({ ...varState, popup: false })}
                        selectedImages={state.image_id?.map((media_id, index) => ({
                            media_id: media_id,
                            media_url: state.image_url[index]
                        })) ?? []}
                    />
                }
                cancel={() => setVarState({ ...varState, popup: false })}
                modalSize='xl'
                modalclass="upload_media_lib_popup"
                title={t('Media library')}
            />}
        </Layout>
    )
}

export default CreateDataEntry;
