import Renewal_lite from '../../../static/icons/shipment/Reneval_lite';
import Accessories from '../../../static/icons/shipment/Accessories';
import Tier from '../../../static/icons/shipment/Tier';
import Recycle from '../../../static/icons/shipment/Recycle';
import IconData from '../../../static/IconData';
import { t } from '../../Translations/TranslationUtils';

//Sorting
export const sortCategories = [
  {
    name: 'renewable',
    icon: Renewal_lite,
  },
  {
    name: 'tier_0',
    icon: Tier,
  },
  {
    name: 'tier_3',
    icon: Tier,
  },
  {
    name: 'recycle',
    icon: Recycle,
  },
  {
    name: 'out_of_scope',
    icon: Accessories,
  },
];

export const CommonConstants = {
  SAVE: "Save",
  BACK: "Back",
  NEXT: "Next",
  SKIP: "Skip",
  YES_POPUP: "Yes, start (00:00)",
  YES_START: "Yes, start",
  NO_POPUP: "No, hold on",
  SAVE_AND_CREATE_NEW_ENTRY: "Save & Create new entry",
  SAVE_AND_GENERATE_BARCODE: "Save and Generate barcode",
  ADD: "Add",
  NO_RESULTS_FOUND: "No results found",
  BACK_TO_DASHBOARD: "Back to dashboard",
  SEARCH: "Search",
  GOT_IT: "Got it",
  YES: "Yes",
  NO: "No",
  MAYBE: "Maybe",
  SAVE_AND_NEXT: "Save & next",
  OVERVIEW: 'Overview',
  ISSUE: 'Issue',
  SOMETHING_WENT_WRONG: 'Something went wrong',
  SAVE_AND_SCAN_NEXT: 'Save & Scan next',
  CANCEL: 'Cancel',
  CLEAR: 'Clear'
};

export const ShipmentConstants = {
  STANDARD_WORKFLOW: "Standard workflow",
  MANAGE_SHIPMENT: "Manage shipment"
}

export const SortingConstants = {
  ERROR_MESSAGE1: "Counts are exceeding the present flow counts",
  ERROR_MESSAGE2: "Counts are less than the initial counts",
  ERROR_MESSAGE3: "Next flow will not accept negative items",
  POPUP_BODY: "Ready to start",
  TOTAL_TIME: "Total time taken",
  SUCCESS_MESSAGE: "We have successfully sorted the products",
};

export const TiersConstants = {
  TIER3: {
    "name": "Tier 3",
    "id": "tier3",
    "icon": IconData.Tier
  },
  RECYCLE: {
    "name": "Recycle",
    "id": "recycle",
    "icon": IconData.Tier
  },
  TIER1: {
    "name": "Tier 01",
    "id": "tier1",
    "icon": IconData.Tier
  },
  TIER2: {
    "name": "Tier 02",
    "id": "tier2",
    "icon": IconData.Tier
  }
}

export const DataEntryConstants = {
  DATA_ENTRY_TABLE_HEADERS: [
    { name: t('SKU number'), width: 20 },
    { name: t('Serial number'), width: 16 },
    { name: t('Product color'), width: 13 },
    { name: t('Product size'), width: 13 },
    { name: t('Product type'), width: 13 },
    { name: t('Stage'), width: 13 }
  ],
  DATA_ENTRY_TABLE_VALUES: [
    'sku',
    'serial_number',
    'colorlabel',
    'sizelabel',
    'product_type_label',
    'stage_name',
  ],
  SUGGESTION_KEYS: [
    'product_description',
    'sku_number',
    'style_number'
  ],
  CATALOGUE_FIELDS: [
    'sku_number',
    'style_number',
    'color_id',
    'size_id',
    'gender_id',
    'product_group_id',
    'product_type_id',
    'weight',
    'fibre_content_id'
  ],
  RENEWAL_TABLE_HEADERS: [
    { name: t('Serial number'), width: 50 },
  ],
  RENEWAL_TABLE_VALUES: [
    'serial_number',
  ],
  ENTER_SEARCH_VALUE: "Enter search value",
  CHECK: "Check",
  NO_RESULTS_FOUND: "No results found",
  SAVE_AND_GENERATE_BARCODE: "Save and Generate barcode",
  SAVE_AND_NEXT: 'Save and next',
  ADD: "Add",
  MANAGE_DATA_ENTRY: "Manage data entry"
};

export const QCConstants = {
  TABLE_HEADERS: [
    { name: t('Serial number'), width: 22 },
    { name: t('Style number'), width: 22 },
    { name: t('Issue count'), width: 22 },
    { name: t('Product type'), width: 22 },
  ],
  TABLE_VALUES: [
    'serial_number',
    'style_number',
    'issue_count',
    'product_type',
  ],
};

export const QaConstants = {
  TABLE_HEADERS: [
    { name: t('Serial number'), width: 40 },
    { name: t('Product type'), width: 24 },
    { name: t('Warehouse location'), width: 22 },
  ],
  TABLE_VALUES: [
    'serial_number',
    'product_type',
    'warehouse_location'
  ],
  FORM_KEYS: [
    'issue_size',
    // 'issue_location',
    'repair_assessment',
    'work_area'
  ],
  ISSUE_REJECTED_MESSAGE: "Issue remains unresolved",
  ISSUE_FORWARDED_MESSAGE: "Issue has been forwarded",
  SEND_TO_RECYCLE_AND_SCAN_NEXT: 'Send to recycle & scan next'
};

export const RepairConstants = {
  TABLE_HEADERS: [
    { name: t('Serial number'), width: 80 }
  ],
  TABLE_VALUES: [
    'serial_number'
  ],
};

export const ConfigConstants = {
  SIZE: {
    "type": "config-data",
    "model": "Size",
    "activestatus": "true"
  },
  CATEGORY: {
    "type": "config-data",
    "model": "Category",
    "activestatus": "true"
  },
  COLOUR: {
    "type": "config-data",
    "model": "colour",
    "activestatus": "true"
  },
  WORKAREA: {
    "type": "config-data",
    "model": "WorkArea",
    "activestatus": "true"
  },
  ZONE: {
    "type": "config-data",
    "model": "Zone",
    "activestatus": "true"
  },
  ENVIRONMENT: {
    "type": "config-data",
    "model": "Environment",
    "activestatus": "true"
  },
  ISSUETYPE: {
    "type": "config-data",
    "model": "IssueType",
    "activestatus": "true"
  },
  CLEANPROCESS: {
    "type": "config-data",
    "model": "RenewalCleanProcess",
    "activestatus": "true"
  },
  FIBRECONTENT: {
    "type": "config-data",
    "model": "FibreContent",
    "activestatus": "true"
  },
  ACTIVE_WAREHOUSELOCATION: {
    "type": "config-data",
    "model": "WarehouseLocation",
    "activestatus": "true"
  },
  ALL_WAREHOUSELOCATION: {
    "type": "config-data",
    "model": "WarehouseLocation",
  },
  PRODUCTTYPE: {
    "type": "config-data",
    "model": "ProductType",
    "activestatus": "true"
  },
  ALL_PRODUCTTYPE: {
    "type": "config-data",
    "model": "ProductType"
  },
  ISSUESIZE: {
    "type": "config-data",
    "model": "IssueSize",
    "activestatus": "true"
  },
  ISSUELOCATION: {
    "type": "config-data",
    "model": "IssueLocation",
    "activestatus": "true"
  },
  REPAIRASSESSMENT: {
    "type": "config-data",
    "model": "RepairAssessment",
    "activestatus": "true"
  },
  GENDER: {
    "type": "config-data",
    "model": "Gender",
    "activestatus": "true"
  },
  MERCHRACK: {
    "type": "config-data",
    "model": "MerchRack",
    "activestatus": "true"
  },
  ACTIVEPRODUCTGROUP: {
    "type": "config-data",
    "model": "ProductGroup",
    "activestatus": "true"
  },
  ALLPRODUCTGROUP: {
    "type": "config-data",
    "model": "ProductGroup",
  },
  STAINTREATMENT: {
    "type": "config-data",
    "model": "StainTreatment",
    "activestatus": "true"
  },
  BRANDPARTNER: {
    "type": "master-data",
    "model": "BrandPartner",
    "activestatus": "true",
    "issaved": "true"
  },
  PRODUCTTIER: {
    "type": "config-data",
    "model": "ProductTier",
    "activestatus": "true"
  },
  BPWISE_ISSUE_TYPE: {
    "tableid": "issue_type_id",
    "table": "cd_issue_type",
    "bptable": "md_brand_partner_issue_type",
    "activestatus": "true"
  },
  BPWISE_WORK_AREA: {
    "tableid": "work_area_id",
    "table": "cd_work_area",
    "bptable": "md_brand_partner_work_area",
    "activestatus": "true"
  },
  ALL_BPWISE_PRODUCT_GROUP: {
    "tableid": "product_group_id",
    "table": "cd_product_group",
    "bptable": "md_brand_partner_product_group",
  },
  ALL_BPWISE_LOCATIONS: {
    "tableid": "product_group_id",
    "table": "cd_product_group",
    "bptable": "md_brand_partner_product_group",
  },
  ACTIVE_BPWISE_PRODUCT_GROUP: {
    "tableid": "product_group_id",
    "table": "cd_product_group",
    "bptable": "md_brand_partner_product_group",
    "activestatus": "true"
  },
  ALL_BPWISE_MERCH_RACK: {
    "tableid": "merch_rack_id",
    "table": "cd_merch_rack",
    "bptable": "md_bp_merch_rack",
  },
  BPWISE_WAREHOUSE_LOCATION: {
    "tableid": "warehouse_location_id",
    "table": "cd_warehouse_location",
  },
  SORTCATEGORY: {
    "type": "config-data",
    "model": "SortCategory",
    "activestatus": "true"
  },
}

export const ProductCatalogueConstants = {
  PRODUCT_CATALGUE_TABLE_HEADERS: [
    { name: t("Style number"), handle: "style_number", width: 8 },
    { name: t("SKU number"), handle: "sku_number", width: 8 },
    { name: t("UPC"), handle: "upc", width: 8 },
    { name: t("Brand partner"), handle: "brand_partner_title", width: 8 },
    { name: t("Product type"), handle: "product_type", width: 7 },
    { name: t("Gender"), handle: "gender", width: 5 },
    { name: t("Country"), handle: "country", width: 8 },
    { name: t("MSRP"), handle: "msrp", width: 7 },
    { name: t("Color name"), handle: "color_name", width: 5 },
    { name: t("Hex color code"), handle: "hex_color_code", width: 5 },
    { name: t("Product description"), handle: "product_description", width: 8 },
    { name: t("Product group"), handle: "product_group", width: 8 },
    { name: t("Currency"), handle: "currency", width: 5 },
    { name: t("Renewal fee"), handle: "renewal_fee", width: 7 },
    { name: t("Size"), handle: "size", width: 7 },
    { name: t("Weight"), handle: "weight", width: 7 },
    { name: t("Fabric content"), handle: "fabric_content", width: 7 },
  ],
  DATA_ENTRY_TABLE_VALUES: [
    'style_number',
    'sku_number',
    'upc',
    'brand_partner_title',
    'product_type',
    'gender',
    'country',
    'msrp',
    'color_name',
    'hex_color_code',
    'product_description',
    'product_group',
    'currency',
    'renewal_fee',
    'size',
    'weight',
    'fabric_content',
  ],
};

export const ShipmentWeightTypes =
  [
    { value: 0, label: t("Kilogram"), name: "weight_type" },
    { value: 1, label: t("Pounds"), name: "weight_type" }
  ]

export const HoldItemsConstants = {
  HOLD_OVERVIEW_TABLE_HEADERS: [
    { name: t('Serial number'), width: 10 },
    { name: t('Brand partner'), width: 13 },
    { name: t('Warehouse location'), width: 10 },
    { name: t('Shipment'), width: 10 },
    { name: t('Product tier'), width: 10 },
    { name: t('Stage'), width: 8 },
    { name: t('status'), width: 10 },
    { name: t('Hold/Updated by'), width: 10 },
    { name: t('Hold/Updated on'), width: 12, issort: true },
    { name: t('Actions'), width: 10 },
  ],
  HOLD_OVERVIEW_TABLE_VALUES: [
    'serial_number',
    'brand_partner',
    'warehouse_location',
    'shipment_title',
    'tier_name',
    'stage_name',
    'status',
    'hold_by',
    'hold_on',
    'icon',
  ],
  HOLDOVERVIEW: "Operations overview"
};

export const TimeTrackerOverviewConstants = {
  TIMETRACKEROVERVIEW: "Time tracker overview",
  TOTALTIME: "Total time",
  AVGTIME: "Average time",
  COUNT: "Number of items"
};
export const LaundryInternalStages = [
  { stage_id: 1, title: "Stain treatment", stage_name: "stain_treatement", type: "stain_treatment", icon: IconData.LaundryArrivalIcon },
  { stage_id: 2, title: "Washer", stage_name: "washer", type: "washer", icon: IconData.washer },
  { stage_id: 3, title: "Ozone cabinet", stage_name: "ozone_cabinet", type: "washer", icon: IconData.Ozone_cabinet },
  { stage_id: 4, title: "Dryer", stage_name: "dryer", type: "washer", icon: IconData.Dryer },
  { stage_id: 5, title: "Air dryer", stage_name: "air_dryer", type: "washer", icon: IconData.AirDryer },
  { stage_id: 7, title: "Steam cabinet", stage_name: "steam_cabinet", type: "washer", icon: IconData.SteamCabinet },
  { stage_id: 6, title: "Data entry", stage_name: "data_entry", type: "washer", icon: IconData.DataEntry2 },
]
export const PREPUBLISH = {
  PUBLISHED_TABLE_HEADERS: [
    { name: t('Serial number'), width: 20 },
    { name: t('Brand partner'), width: 35 },
    { name: t('Shipment'), width: 25 },
    { name: t('Published at'), width: 20 },
  ],

  PUBLISHED_TABLE_VALUES: [
    'serial_number',
    'brandpartner',
    'shipments',
    'publishedat',
  ],
};

export const PublishTabs = {
  "active": {
    "key": "active",
    "title": "Not Published"
  },
  "published": {
    "key": "published",
    "title": "Published"
  },
  "rejected": {
    "key": "rejected",
    "title": "Rejected"
  }
};

export const REJECTEDITEMS = {
  REJECTED_TABLE_HEADERS: [
    { name: t('Serial number'), width: 20 },
    { name: t('Rejected reason'), width: 35 },
    { name: t('Rejected date'), width: 30 },
  ],

  REJECTED_TABLE_VALUES: [
    'serial_number',
    'rejected_reason',
    'rejected_date',
  ],
};