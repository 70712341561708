import React, { useState, useEffect } from "react";
import Button from "../atoms/Button";
import { t } from "../../pages/Translations/TranslationUtils";
import LabelField from "../atoms/LabelField";
import { ConfigConstants } from "../../pages/Shipment/Constants/ShipmentConstants";
import { fetchBpwiseList, fetchList, fetchWorkArea } from "../../routes/ApiEndPoints";
import { APICALL } from "../../services/ApiServices";
import CommonServices from "../../services/CommonServices";
import MultiSelectField from "../atoms/MultiSelectField";
import styles from "../organisms/organisms.module.css";
import Edit from "../../static/icons/Edit";
import Delete from "../../static/icons/Delete";
import IconData from "../../static/IconData";
import { selectedIssueInterphase, PredefinedIssueErrorsInterphase, SelectedOption } from "../../pages/BrandPartner/components/EighthTab";

interface PreDefinedQCIssuesProps {
  selectedIssues: selectedIssueInterphase[];
  setSelectedIssues: React.Dispatch<React.SetStateAction<selectedIssueInterphase[]>>;
  predefinedIssueErrors: PredefinedIssueErrorsInterphase;
  setPredefinedIssueErrors: React.Dispatch<React.SetStateAction<PredefinedIssueErrorsInterphase>>;
}


const PreDefinedQCIssues: React.FC<PreDefinedQCIssuesProps> = ({ selectedIssues, setSelectedIssues, predefinedIssueErrors, setPredefinedIssueErrors }) => {

  const bp_id = sessionStorage.getItem("brand_partner_id");
  const [issueType, setIssueType] = useState<SelectedOption[]>([]);
  const [zone, setZone] = useState<SelectedOption[]>([]);
  const [workarea, setWorkArea] = useState<SelectedOption[]>([]);
  const [selectedIssueType, setSelectedIssueType] = useState<SelectedOption | null>(null);
  const [selectedZone, setSelectedZone] = useState<SelectedOption | null>(null);
  const [selectedEnvironment, setSelectedEnvironment] = useState<SelectedOption | null>(null);


  useEffect(() => {
    const workflowState = sessionStorage.getItem('workflowState')
      ? JSON.parse(sessionStorage.getItem('workflowState') ?? '')
      : null;
    setSelectedIssues(workflowState?.predefinedIssueState ?? []);
  }, [])

  useEffect(() => {
    const fetchConfigData = async () => {
      try {
        let postdata = {
          "method": "POST",
          "data": {
            "list": [
              ConfigConstants.ZONE
            ]
          }
        }
        const response = await APICALL.service(fetchList, 'POST', postdata, true);
        if (response?.status === 200) {
          const mappedZone: SelectedOption[] = CommonServices.sortAlphabattically(response?.data?.Zone ?? [])?.map(
            (item: any) => ({
              value: item.zone_id,
              label: item.title
            })
          );
          setZone(mappedZone)
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchConfigData();
  }, []);

  // useEffect(() => {
  //   const fetchBpwiseConfigData = async () => {
  //     try {
  //       let postdata = {
  //         "method": "POST",
  //         "data": {
  //           "list": [
  //             ConfigConstants.BPWISE_ISSUE_TYPE,
  //           ],
  //           "bp_id": [bp_id]
  //         }
  //       }
  //       const response = await APICALL.service(fetchBpwiseList, 'POST', postdata, true);
  //       if (response?.status === 200) {
  //         const mappedissuetypes: SelectedOption[] = CommonServices.sortAlphabattically(response?.data?.cd_issue_type ?? [])?.map(
  //           (item: any) => ({
  //             value: item.issue_type_id,
  //             label: item.title,
  //             color: item.color,
  //           })
  //         );
  //         setIssueType(mappedissuetypes)
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  //   fetchBpwiseConfigData();
  // }, []);

  useEffect(() => {
    const fetchIssuesFromSession = () => {
      try {
        const storedIssues = sessionStorage.getItem("allIssues");
        if (storedIssues) {
          const parsedIssues = JSON.parse(storedIssues);
          const mappedIssues: SelectedOption[] = parsedIssues.map((item: any) => ({
            value: item.value,
            label: item.label,
            color: item.color,
          }));
          setIssueType(mappedIssues);
        } else {
          console.log("No issues found in session storage.");
        }
      } catch (error) {
        console.error("Error fetching issues from session storage: ", error);
      }
    };
    fetchIssuesFromSession();
  }, []);

  const getWorkArea = async (e: any) => {
    const zone = e?.value;
    try {
      let workarea = null;
      let postdata = {
        'method': 'GET',
        "data": {}
      }
      let url: string;
      url = zone != 'all' ?
        fetchWorkArea + '/' + zone :
        fetchWorkArea;
      workarea = await APICALL.service(url, 'POST', postdata, true);

      if (workarea?.status == 200) {
        const mappedWorkArea: SelectedOption[] = workarea.data?.map(
          (item: any) => ({
            value: item.environment_id,
            label: item.title
          })
        );
        setWorkArea(mappedWorkArea);
      } else {
        setWorkArea([]);
        setSelectedEnvironment(null)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleIssueTypeSelect = (e: any) => {
    setSelectedIssueType(e);
  }

  const handleZoneSelect = (e: any) => {
    getWorkArea(e)
    setSelectedZone(e);
  }

  const handleEnvironmentSelect = (e: any) => {
    setSelectedEnvironment(e);
  }

  const handleSave = () => {
    const errors: PredefinedIssueErrorsInterphase = { issue_type: "", zone: "", environment: "" };

    !selectedIssueType ? errors.issue_type = "Issue Type is required." : "";
    !selectedZone ? errors.zone = "Zone is required." : "";
    workarea.length > 0 && !selectedEnvironment ? errors.environment = "Environment is required." : "";

    setPredefinedIssueErrors(errors);

    if (Object.values(errors).some((value) => value !== "")) {
      return;
    } else {
      const newIssue: selectedIssueInterphase = {
        issue_type: selectedIssueType,
        zone: selectedZone,
        environment: selectedEnvironment,
      };

      // Update the state with the new issue
      setSelectedIssues((prev) => [...prev, newIssue]);

      // Clear the selected values
      setSelectedIssueType(null);
      setSelectedZone(null);
      setSelectedEnvironment(null);
      setWorkArea([])
    }
  };


  const handleEdit = (index: number) => {
    const issueToEdit = selectedIssues[index];
    if(issueToEdit.environment) getWorkArea(issueToEdit.zone)
    setSelectedIssueType(issueToEdit.issue_type);
    setSelectedZone(issueToEdit.zone);
    setSelectedEnvironment(issueToEdit.environment);
    handleDelete(index); // Remove the issue from the list to avoid duplicates when re-saving.
  };

  const handleDelete = (index: number) => {
    setSelectedIssues((prev) => prev.filter((_, i) => i !== index));
  };

  const removeError = (field: keyof typeof predefinedIssueErrors) => {
    const updatedErrors = { ...predefinedIssueErrors };
    updatedErrors[field] = "";
    setPredefinedIssueErrors(updatedErrors);
  };

  return (
    <div className="row">
      <>
        <div className="col-8">
          <h4>{t("Saved Issues")}</h4>
          {selectedIssues?.length > 0 ? (
            <div className="row">
              {selectedIssues.map((issue, index) => (
                <div key={index} className="col-4 mb-4">
                  <div style={{ backgroundColor: `${issue?.issue_type?.color}` }} className="p-3 h-100">
                    <div className={`d-flex justify-content-end ${styles.qcedit} ${styles.qceditblock} ${styles.pdqcIssues}`}>
                      <div
                        title={t('Edit')}
                        onClick={() => handleEdit(index)}
                        className={`me-2 mt-2 hoversvg ${styles.qcedit}`}>
                        <Edit />
                      </div>
                      <div
                        title={t('Edit')}
                        onClick={() => handleDelete(index)}
                        className={`me-2 mt-2 hoversvg ${styles.qcedit}`}>
                        <Delete />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-3 text-center">
                        <span className={`${styles.qclabel} mb-1`}> {IconData.Issue}</span>
                      </div>
                      <div className="col-9 align-self-center text-white text-break">
                        {issue?.issue_type?.label}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-3 text-center">
                        <span className={`${styles.qclabel} mb-1`}> {IconData.Zone}</span>
                      </div>
                      <div className="col-9 align-self-center text-white text-break">
                        {issue?.zone?.label}
                      </div>
                    </div>
                    {issue?.environment?.value && <div className="row">
                      <div className="col-3 text-center">
                        <span className={`${styles.qclabel} mb-1`}> {IconData.Issuetype}</span>
                      </div>
                      <div className="col-9 align-self-center text-white text-break">
                        {issue?.environment?.label}
                      </div>
                    </div>}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p>No issues saved.</p>
          )}        </div>
        <div className="col-4">
          <div className="border p-3">
            <div className="mb-3">
              <LabelField title={t("Select Issue")} mandatory />
              {issueType.length > 0 && <MultiSelectField
                name="issue_type"
                options={issueType}
                standards={selectedIssueType}
                handleChange={handleIssueTypeSelect}
                handleClick={() => removeError("issue_type")}
                className={`input-lg`}
                isClearable
                isSearchable
                customStyle={
                  predefinedIssueErrors.issue_type
                    ? { border: "1px solid red", borderRadius: "5px" }
                    : undefined
                }
              />}
              {predefinedIssueErrors.issue_type && <LabelField title={t(`${predefinedIssueErrors.issue_type}`)} className="text-danger" />}
            </div>
            <div className="mb-3">
              <LabelField title={t("Select Zone")} mandatory />
              {zone.length > 0 && <MultiSelectField
                name="zone"
                options={zone}
                standards={selectedZone}
                handleChange={handleZoneSelect}
                handleClick={() => removeError("zone")}
                className={`input-lg`}
                isClearable
                isSearchable
                customStyle={
                  predefinedIssueErrors.zone
                    ? { border: "1px solid red", borderRadius: "5px" }
                    : undefined
                }
              />}
              {predefinedIssueErrors.zone && <LabelField title={t(`${predefinedIssueErrors.zone}`)} className="text-danger" />}

            </div>
            {(workarea.length > 0 || selectedEnvironment) && <div className="mb-3">
              <LabelField title={t("Select Environment")} mandatory />
              <MultiSelectField
                name="environment"
                options={workarea}
                standards={selectedEnvironment}
                handleChange={handleEnvironmentSelect}
                handleClick={() => removeError("environment")}
                className={`input-lg`}
                isClearable
                isSearchable
                customStyle={
                  predefinedIssueErrors.environment
                    ? { border: "1px solid red", borderRadius: "5px" }
                    : undefined
                }
              />
              {predefinedIssueErrors.environment && <LabelField title={t(`${predefinedIssueErrors.environment}`)} className="text-danger" />}
            </div>}

            <div className="text-end">
              <Button
                title={t("Add")}
                handleClick={handleSave}
                className="submit-btn shadow-none"
              />
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default PreDefinedQCIssues;
