import React from 'react'
import Edit from '../../static/icons/Edit';
import Delete from '../../static/icons/Delete';
import Archive from '../../static/icons/Archive';
import Button from './Button';
import Unarchive from '../../static/icons/Unarchive';
import IconWithLabel from './IconWithLabel';
import IconData from '../../static/IconData';
import { FaSort } from 'react-icons/fa';
import { t } from '../../pages/Translations/TranslationUtils';
import { CommonConstants } from '../../pages/Shipment/Constants/ShipmentConstants';
import styles from "../../pages/Shipment/shimpment.module.css";

interface Props {
    sort?: any,
    data?: any;
    headers?: any;
    values?: any;
    isAction?: boolean;
    actionText?: string;
    actionClass?: string;
    editClass?: string;
    deleteClass?: string;
    archiveClass?: string;
    barcodeClass?: string;
    handleSort?: (value: string) => void;
    handleEdit?: (value: any) => void;
    handleDelete?: (value: any) => void;
    handleArchive?: (value: any) => void;
    handleBarcode?: (value: any) => void;
    handleDownload?: (value: any) => void;
    handleView?: (value: any) => void;
    handleMoveBackItem?: (value: any) => void;
    handlePrint?: (value: any, value1: any, value2: any) => void;
    flatValues?: string[];
}

const TableStructure: React.FC<Props> = (props) => {

    const flatten = (value: any) => {
        const newData = value?.map((obj: any) => obj.label).join(', ');
        return newData;
    }

    return (
        <table className="table">
            <thead className='position-sticky'>
                <tr>
                    {props.headers?.map((value: any) => (
                        <th
                            key={value.name}
                            className='border-bottom-0'
                            style={{ width: `${value.width}%` }}
                        >
                            {value.name} {value?.issort && <span className='cursor-pointer' title={t(`Sort by ${props?.sort === 'ASC' ? 'descending' : 'ascending'}`)} onClick={() => props.handleSort?.('sort')}> <FaSort /> </span>}
                        </th>
                    ))}
                    {props.isAction && <th className={`border-bottom-0 ${props.actionClass ?? 'table_actions'}`}>
                        {props.actionText ?? t('Actions')}
                    </th>}
                </tr>
            </thead>
            <tbody>
                {props.data.length ? Object.values(props.data).map((value: any, index) => (
                    <tr key={index}>
                        {props.values?.map((column: any) => (
                            <td
                                key={column}
                                className="text-break"
                            >
                                {/* {value[column] ?? ''} */}
                                {props.flatValues?.includes(column) ? flatten(value[column]) : value[column]}
                            </td>
                        ))}
                        {props.isAction && <td className="text-break gap-3">
                            <div className="d-flex justify-content-center">
                                {props.handleEdit && <Button
                                    title={<Edit />}
                                    hoverText={t('Edit')}
                                    className={`${styles.printbarcodeicon}
                                    ${props.editClass ??
                                        'px-0 border-0 bg-transparent shadow-none manage-users-action mx-2'}`
                                    }
                                    handleClick={() => props.handleEdit?.(value)}
                                />}
                                {props.handleDelete && <Button
                                    title={<Delete />}
                                    className={`${styles.printbarcodeicon}
                                    ${props.deleteClass ??
                                        'px-0 border-0 bg-transparent shadow-none manage-users-action mx-2'}`
                                    }
                                    handleClick={() => props.handleDelete?.(value)}
                                />}
                                {props.handleView && <Button
                                    title={IconData.Openeye}
                                    hoverText={t("View")}
                                    className={`${styles.printbarcodeicon}
                                    ${props.deleteClass ??
                                        'px-0 border-0 bg-transparent shadow-none manage-users-action mx-2'}`
                                    }
                                    handleClick={() => props.handleView?.(value)}
                                />}
                                {props.handleArchive && <Button
                                    title={value.status ? <Archive /> : <Unarchive />}
                                    hoverText={value.status ? t('Deactivate') : t('Activate')}
                                    className={
                                        `${styles.printbarcodeicon}
                                        ${props.archiveClass ??
                                        'px-0 border-0 bg-transparent shadow-none manage-users-action mx-2'}`
                                    }
                                    handleClick={() => props.handleArchive?.(value)}
                                />}
                                {props.handleBarcode && <Button
                                    title={
                                        <IconWithLabel
                                            hoverText={t("Print barcode")}
                                            classname1={`${styles.printbarcodeicon}`}
                                            svgData={IconData.PrinterIcon}
                                        />
                                    }
                                    className={
                                        props.barcodeClass ??
                                        'px-0 border-0 bg-transparent shadow-none manage-users-action mx-2'
                                    }
                                    handleClick={() => props.handleBarcode?.(value)}
                                />}
                                {props.handleMoveBackItem && <Button
                                    title={
                                        <IconWithLabel
                                            classname1={`${styles.printbarcodeicon}`}
                                            hoverText={t("Move item back to workflow")}
                                            svgData={IconData.MoveItemBackToWorkflow}
                                        />
                                    }
                                    className={
                                        props.barcodeClass ??
                                        'me-2 border-0 bg-transparent shadow-none manage-users-action'
                                    }
                                    handleClick={() => props.handleMoveBackItem?.(value)}
                                />}
                                {props.handleDownload && <Button
                                    title={
                                        <IconWithLabel
                                            classname1={`${styles.printbarcodeicon}`}
                                            svgData={IconData.DownloadIcon}
                                        />
                                    }
                                    className={
                                        props.barcodeClass ??
                                        'px-0 border-0 bg-transparent shadow-none manage-users-action mx-2'
                                    }
                                    handleClick={() => props.handleDownload?.(value)}
                                />}
                                {props.handlePrint && <Button
                                    title={
                                        <IconWithLabel
                                            classname1={`${styles.printbarcodeicon}`}
                                            svgData={IconData.PrinterIcon}
                                        />
                                    }
                                    className={
                                        props.barcodeClass ??
                                        'px-0 border-0 bg-transparent shadow-none manage-users-action mx-2'
                                    }
                                    handleClick={() => props.handlePrint?.(value.title, value.barcode_id, value.barcode_url)}
                                />}
                            </div>
                        </td>}
                    </tr>
                )) :
                    <td colSpan={props.isAction ? (props.headers.length + 1) : props.headers.length} className="text-center text-danger">
                        {t(CommonConstants.NO_RESULTS_FOUND)}
                    </td>
                }
            </tbody>
        </table>
    )
}

export default TableStructure;