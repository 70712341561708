import React, { useState, useRef, useEffect } from 'react';
import styles from "./shimpment.module.css"
import Play from '../../static/icons/shipment/Play';
import Pause from '../../static/icons/shipment/Pause';
import Popup from '../../utils/popup';
import CommentsBody from '../../components/molecules/CommentsBody';
import IconData from '../../static/IconData';
import { APICALL } from '../../services/ApiServices';
import { addNewComments, createDataEntry, terminateItem } from '../../routes/ApiEndPoints';
import { t } from '../Translations/TranslationUtils';
import FormValidation from '../../services/FormValidation';
import { useNavigate } from 'react-router-dom'
import customAlert from '../../components/atoms/CustomAlert';
import CommonShipmentServices from '../../services/CommonShipmentServices';
import Timer from '../../components/atoms/Timer';
import MoveItemToAnyStage from '../../components/organisms/MoveItemToAnyStage/MoveItemToAnyStage';
import { CommonConstants } from './Constants/ShipmentConstants';
import { useUserContext } from '../../routes/Contextlib';
import { useInactivityTimeoutContext } from '../../services/useInactivityTimeoutContext';

type Headerprop = {
  pagename: string;
  timer: boolean;
  comments?: any;
  hold?: any;
  terminate?: any;
  stage?: string;
  ongoing?: boolean;
  totaltime?: number;
  setTimerRunning?: (timer: boolean) => void;
  logo?: any
};

const Shipmentheader: React.FC<Headerprop> = ({
  pagename,
  timer,
  comments,
  hold,
  terminate,
  stage,
  ongoing = false,
  totaltime,
  setTimerRunning,
  logo
}) => {
  // timer testing starts
  const [running, setRunning] = useState(true);
  const timerRef = useRef<number | undefined>(undefined);
  const bpDetails = JSON.parse(localStorage.getItem('bp_details') ?? '{}');
  const [showCommentsPopup, setShowCommentsPopup] = useState(false);
  const [showHoldPopup, setShowHoldPopup] = useState(false);
  const [showTerminatePopup, setShowTerminatePopup] = useState(false);
  const [showMoveItemPopup, setShowMoveItemPopup] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [commentList, setCommentList] = useState<any[]>([]);
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();
  const localdata = CommonShipmentServices.getPostData();
  const screendisable = document.getElementById("screen-disable-div-id");
  const { user } = useUserContext();
  const { inactivityTimeoutState, updateInactivityTimeout } = useInactivityTimeoutContext();

  useEffect(() => {
    if (window.location.pathname !== "/reports/view-renewal-record" && Object.keys(bpDetails).length === 0) {
      navigate('/shipment');
    }
    if (comments?.type_id != null) {
      setCommentList(comments?.commentdata?.stagewise_comments);
    }
    if (!ongoing) setRunning(false);
    setRunning(ongoing)
  }, [comments, ongoing]);

  const handleAddComment = async () => {
    try {
      if (FormValidation.textareaValidation(commentText) === "") {
        const postdata = {
          stagename: comments?.stagename,
          type: comments?.type,
          type_id: comments?.type_id,
          comments: commentText,
          user_id: user?.user_id
        };
        const response = await APICALL.service(addNewComments, "POST", postdata);
        if (response.status === 200) {
          setCommentList(response?.data?.stagewise_comments);
          setCommentText("");
        }
      } else {
        setShowError(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleHoldSubmit = async () => {
    try {
      const postdata = {
        ...localdata,
        renewal_id: comments.type_id,
        tier: bpDetails.product_tier,
        status: "hold",
        user_id: user?.user_id,
        stagename: comments?.stagename,
      };

      const response = await APICALL.service(createDataEntry, "POST", postdata);
      if (response.status !== 200) {
        customAlert("error", t("Error while adding item to tier"), 6000)
      }
      navigate(localStorage?.getItem('shipment_dashboard_url') ?? "/shipment-dashboard")
      setShowHoldPopup(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTerminate = async () => {
    console.log(localdata, bpDetails);
    try {
      const postdata = {
        ...localdata,
        renewal_id: comments.type_id,
        tier: bpDetails.product_tier,
        status: "active",
        user_id: user?.user_id,
        present_stage: comments?.stagename,
        recycle_merch_rack_id: Number(process.env.REACT_APP_RECYCLE_MERCHRACK_ID)
      };

      const response = await APICALL.service(terminateItem, "POST", postdata);
      if (response.status == 200) {
        navigate(terminate)
      } else {
        customAlert("error", t("Error while terminating item."), 6000)
        setShowTerminatePopup(false)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handlePausePlay = async () => {
    if (ongoing) {
      if (screendisable !== null) {
        running ?
          screendisable.setAttribute("style", "display:block;") :
          screendisable.setAttribute("style", "display:none;");
      }
      let type = comments?.type_id ? 'renewal' : 'bp';
      let typeid = comments?.type_id ? comments?.type_id : localdata?.brand_partner_id;
      let pause = await CommonShipmentServices.saveTimerApi(
        type,
        typeid,
        user?.user_id,
        stage,
        !running
      );

      if (pause) {
        setRunning(!running);
        setTimerRunning && setTimerRunning(!running);
      }
    }
  }

  const shipmentRedirect = () => {
    if (bpDetails?.title) {
      navigate(localStorage?.getItem('shipment_dashboard_url') ?? "/shipment-dashboard")
    }
  }
  // timer testing ends

  return (
    <div className="d-flex justify-content-between align-items-center my-2">
      <div>
        <div className={`${styles.shimpmentlogo}`} onClick={shipmentRedirect}>
          {bpDetails?.brand_logo ? <img src={bpDetails?.brand_logo} alt="" /> : <img src={(typeof logo !== 'boolean' && logo !== true) ? logo : "../../../logo.png"} alt="" />}
        </div>
      </div>
      <div className={` ${styles.page_title} text-truncate`} title={bpDetails?.title}>
        {pagename + (bpDetails?.title ? ': ' + bpDetails?.title : "")}
      </div>
      <div className={`${styles.shipmenttimer}`}>
        <div className="d-flex align-items-center justify-content-end">
          {(terminate && terminate != "") && <div className=''>
            <span title={t("Terminate item")} className={`me-4 cursor-pointer position-relative 
                    ${styles.shipment_header_icons}`} onClick={() => setShowTerminatePopup(true)}
            >
              {IconData.ClearIcon}
            </span>
          </div>
          }
          {hold && <div className=''>
            <span title={t("Hold item")} className={`me-4 cursor-pointer position-relative 
                    ${styles.shipment_header_icons}`} onClick={() => setShowHoldPopup(true)}
            >
              {IconData.holdIcon}
            </span>
          </div>
          }
          {hold && <div className=''>
            <span title={t("Move item")} className={`me-4 cursor-pointer position-relative 
                    ${styles.shipment_header_icons}`} onClick={() => setShowMoveItemPopup(true)}
            >
              {IconData.MoveItemBackToWorkflow}
            </span>
          </div>
          }
          {comments && <div className=''>
            <span className={`me-4 cursor-pointer position-relative mt-4 d-inline-flex
                    ${styles.shipment_header_icons}`} title={t("View comments")}
              onClick={() => setShowCommentsPopup(true)}
            >
              {IconData.CommentIcon} {commentList?.length > 0 &&
                <span className={`position-absolute ${styles.commentcounts}`}>
                  {commentList?.length > 99 ? "99+" : commentList?.length}
                </span>
              }
            </span>
          </div>
          }
          {timer && <div className='d-flex align-items-center'>
            {ongoing && <span className={`me-4 cursor-pointer ${styles.shipment_header_icons}`}
              onClick={handlePausePlay} title={`${running ? t("Pause") : t("Play")}`}
            >
              {running ? <Pause /> : <Play />}
            </span>}
            <Timer time={totaltime ?? 0} running={running} className={`border rounded px-3 ${styles.timer} ${ongoing ? "" : "disabled_timer"}`} />
          </div>}
        </div>
        {showCommentsPopup && (
          <div className="quickaccess">
            <Popup
              title={t("Add your comments")}
              body={
                <CommentsBody
                  commentText={commentText}
                  setCommentText={setCommentText}
                  commentList={commentList}
                  setCommentList={setCommentList}
                  handleAddComment={handleAddComment}
                  showError={showError}
                  setShowError={setShowError}
                />
              }
              modalSize="commentpopup"
              cancel={() => setShowCommentsPopup(false)}
            />
          </div>
        )}
        {showHoldPopup &&
          <Popup
            body={t("Are you sure you want to hold this item") + '?'}
            yestext={t(CommonConstants.YES)}
            notext={t(CommonConstants.NO)}
            submit={handleHoldSubmit}
            cancel={() => setShowHoldPopup(!showHoldPopup)}
          />
        }
        {showTerminatePopup &&
          <Popup
            title={t("Terminate item.")}
            body={t("Are you sure you want to terminate this item") + '?'}
            yestext={t(CommonConstants.YES)}
            notext={t(CommonConstants.NO)}
            submit={handleTerminate}
            cancel={() => setShowTerminatePopup(!showTerminatePopup)}
          />
        }
        {showMoveItemPopup &&
          <Popup
            title={t("Are you sure you want to move this item") + '?'}
            body={<MoveItemToAnyStage
              renewalid={comments?.type_id}
              stagename={comments?.stagename}
              setShowMoveItemPopup={setShowMoveItemPopup}
            />}
            modalSize="xl"
            bodyclassName={"moving_item_popup"}
            cancel={() => setShowMoveItemPopup(!showMoveItemPopup)}
          />
        }

        {inactivityTimeoutState.showPopup && <Popup
          title={t("You have been inactive on this page") + '!'}
          body={t("Are you still actively working on the item(s)") + '?'}
          modalSize="xl"
          yestext={t("yes")}
          notext={t("no")}
          cancel={() => updateInactivityTimeout({ ...inactivityTimeoutState, userResponse: "no" })}
          submit={() => updateInactivityTimeout({ ...inactivityTimeoutState, userResponse: "yes" })}
        />}
      </div>
    </div>
  );
};

export default Shipmentheader;
