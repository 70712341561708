import { fetchAllBrandPartnerUrl, saveTime } from "../routes/ApiEndPoints";
import { APICALL } from "./ApiServices";
import { useUserContext } from "../routes/Contextlib";

const CommonShipmentServices = {
  getPostData: function (): any {
    const { user } = useUserContext();
    let bpdetails: any = localStorage.getItem("bp_details");
    bpdetails =
      bpdetails != undefined || bpdetails != "" ? JSON.parse(bpdetails) : null;
    let shipmentdetails: any = localStorage.getItem("shipment_details");
    shipmentdetails =
      shipmentdetails != undefined || shipmentdetails != ""
        ? JSON.parse(shipmentdetails)
        : null;
    return {
      brand_partner_id: bpdetails != null ? bpdetails.brand_partner_id : "",
      bpname: bpdetails != null ? bpdetails.title : "",
      shipment_id: shipmentdetails != null ? shipmentdetails.shipment_id : "",
      shipment: shipmentdetails != null ? shipmentdetails.title : "",
      user_id: user?.user_id ?? null,
    };
  },
  getFilteredTitle: function (
    data: any,
    name: string,
    id: any,
    field = ""
  ): any {
    if (id == null) return "";
    let fieldval = field != "" ? field : "title";
    const fieldvalue = data
      ?.filter((item: any) => item[name] == id)
      ?.map((item: any) => item[fieldval]);
    if(fieldvalue?.length > 1) {
      return fieldvalue?.join(', ');
    } else {
      return fieldvalue;
    }
  },
  getFilteredObject: function (list: any, name: string, id: any): any {
    const object = list
      .filter((item: any) => item[name] === id)
      .map((item: any) => item);

    return object;
  },
  saveTimerApi: async function (
    type: string = "renewal",
    parent_id: number | null = null,
    user_id: number | null = null,
    stage: string = "",
    reloaded: boolean = true,
    disabletimetrackerapi: boolean = false,
    timeoutRedirection: number | null = null
  ) {
    try {
      let postdata = {
        type: type,
        parent_id: parent_id,
        stage_name: stage,
        user_id: user_id,
        reloaded: reloaded,
        timeoutRedirection: timeoutRedirection,
      };
      if (!disabletimetrackerapi) {
        const response = await APICALL.service(saveTime, "POST", postdata);
        return response.status === 200 && response.timedata.total_time;
      } else {
        return 0;
      }
    } catch (error) {
      console.log(error);
    }
  },
  setBpDetails: async (id: any) => {
    try {
      const postData = {
        method: "POST",
        data: { brandpartner_id: id },
      };

      const response = await APICALL.service(
        fetchAllBrandPartnerUrl,
        "POST",
        postData,
        true
      );
      if (response?.status == 200) {
        const data = response?.data;
        let bpdata = data?.first[0];
        bpdata.publishable_tiers = data?.first[5];
        let bpPermissiondata = data?.sixth;
        let workflowTimeoutDetails = data?.eighth?.stagewise_timeout;
        let predefinedIssueDetails = data?.eighth?.predefined_issues;

        bpdata.filepath = data?.first[2] != null ? data.first[2].file_path : "";
        localStorage.setItem("bp_details", JSON.stringify(bpdata));
        localStorage.setItem(
          "bp_permissions_details",
          JSON.stringify(bpPermissiondata)
        );
        localStorage.setItem("bp_product_group", JSON.stringify(data.first[1]));
        localStorage.setItem("workflowTimeoutDetails", JSON.stringify(workflowTimeoutDetails));
        localStorage.setItem("predefinedIssueDetails", JSON.stringify(predefinedIssueDetails));
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  },
};
export default CommonShipmentServices;
